<template>
  <div class="main">
    <div class="divBackground">
      <Lottie1
        v-if="activeTeam"
        class="animation1"
        :options="defaultOptions"
        @animCreated="handleAnimation"
      />
      <Lottie1
        v-if="activeTeam"
        class="animationxs"
        :options="defaultOptions3"
      />
      <Lottie1
        v-if="activeTeam"
        class="animationsm"
        :options="defaultOptions4"
      />
      <Lottie1 :options="defaultOptions1" @animCreated="handleAnimation" />
    </div>

    <div class="text">
      <div class="title">
        <div class="rectangle">
          <img src="../../assets/icons/RectangleYellow.svg" alt="yellow" />
        </div>
        Our approach
      </div>

      <p class="subTitle">
        We are built around an <br />
        agile structure
      </p>

      <div
        class="row items-start justify-start"
        :class="{
          'justify-end': $q.screen.gt.sm,
          'justify-center': $q.screen.sm
        }"
      >
        <div class="col-xs-11 col-sm-11 col-md-7 q-mt-lg">
          <div
            class="team-container"
            @mouseenter="playAnimation(5)"
            @mouseleave="PauseAnimation(5)"
          >
            <div class="team-check">
              <Lottie
                renderer="canvas"
                :loop="true"
                :autoplay="false"
                :animation-data="lottieData"
                @getLottieInstance="getLottieInstance5"
              />
            </div>

            <p class="checkSection">
              Unique way of organizing our teams with our customers
            </p>
          </div>
        </div>

        <div class="col-xs-11 col-sm-11 col-md-7">
          <div
            class="team-container"
            @mouseenter="playAnimation(4)"
            @mouseleave="PauseAnimation(4)"
          >
            <div class="team-check">
              <Lottie
                renderer="canvas"
                :loop="true"
                :autoplay="false"
                :animation-data="lottieData"
                @getLottieInstance="getLottieInstance4"
              />
            </div>

            <p class="checkSection">
              We reinvent ourselves and other industries through AI.
            </p>
          </div>
        </div>

        <div class="col-xs-11 col-sm-11 col-md-7">
          <div
            class="team-container"
            @mouseenter="playAnimation(3)"
            @mouseleave="PauseAnimation(3)"
          >
            <div class="team-check">
              <Lottie
                renderer="canvas"
                :loop="true"
                :autoplay="false"
                :animation-data="lottieData"
                @getLottieInstance="getLottieInstance3"
              />
            </div>
            <p class="checkSection">
              We deliver engineering, innovation and design, at scale.
            </p>
          </div>
        </div>

        <div class="col-xs-11 col-sm-11 col-md-7">
          <div
            class="team-container"
            @mouseenter="playAnimation(2)"
            @mouseleave="PauseAnimation(2)"
          >
            <div class="team-check">
              <Lottie
                renderer="canvas"
                :loop="true"
                :autoplay="false"
                :animation-data="lottieData"
                @getLottieInstance="getLottieInstance2"
              />
            </div>
            <p class="checkSection">
              We dream to make the world a better place, one step at a time.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="divBeforeGridTest">
      <div class="row wrap">
        <div class="col-xs-12 col-md-6 brxs">
          <div class="divBeforeImage">
            <img
              v-if="hover"
              width="100%"
              :src="'https://firebasestorage.googleapis.com/v0/b/dev-dd6f7.appspot.com/o/gif%2FDevelopersComprimido.gif?alt=media&token=668dade2-875d-4fa6-93e3-8cd5f8946594'"
              alt="DeveloperGif"
            />
            <img
              v-if="hover1"
              width="100%"
              :src="'https://firebasestorage.googleapis.com/v0/b/dev-dd6f7.appspot.com/o/gif%2FDesignComprimido.gif?alt=media&token=a1965354-b63f-4e44-9bd8-01e09f8ae716'"
              alt="DesingGif"
            />
            <img
              v-if="hover2"
              width="100%"
              :src="'https://firebasestorage.googleapis.com/v0/b/dev-dd6f7.appspot.com/o/gif%2FMarketingComprimido.gif?alt=media&token=0652ab23-9580-4ace-b800-1d7d0a908440'"
              alt="MarketingGif"
            />
            <img
              v-if="hover3"
              width="100%"
              :src="'https://firebasestorage.googleapis.com/v0/b/dev-dd6f7.appspot.com/o/gif%2FDataComprimido.gif?alt=media&token=d11f16c6-83bc-4da2-931d-3ba2851b86e7'"
              alt="DataGif"
            />
          </div>
        </div>
        <div class="col-xs-12 col-md-5">
          <div class="gridpapa">
            <div class="rigthGrid" @mousemove="MoveMouse">
              <div>
                <img
                  v-if="hover"
                  src="../../assets/Backgrounds/Developers.svg"
                  alt="developvers"
                  :style="{
                    position: 'absolute',
                    width: '25%',
                    top: vary,
                    left: varx,
                    opacity: 0.4
                  }"
                />

                <img
                  v-if="hover1"
                  src="../../assets/Backgrounds/UXUI.svg"
                  alt="developvers"
                  :style="{
                    position: 'absolute',
                    width: '25%',
                    top: vary,
                    left: varx,
                    opacity: 0.4
                  }"
                />

                <img
                  v-if="hover2"
                  src="../../assets/Backgrounds/Marketing.svg"
                  alt="developvers"
                  :style="{
                    position: 'absolute',
                    width: '25%',
                    top: vary,
                    left: varx,
                    opacity: 0.4
                  }"
                />

                <img
                  v-if="hover3"
                  src="../../assets/Backgrounds/Data.svg"
                  alt="developvers"
                  :style="{
                    position: 'absolute',
                    width: '25%',
                    top: vary,
                    left: varx,
                    opacity: 0.4
                  }"
                />
              </div>
              <div>
                <div>
                  <div class="title">
                    <div class="rectangle">
                      <img
                        src="../../assets/icons/RectangleOrange.svg"
                        alt="orange"
                      />
                    </div>
                    300dev gold <br />
                    team
                  </div>
                </div>
                <div class="divBeforeOptions">
                  <p
                    :class="[{ titleTestActive: hover }, titleTest]"
                    @mouseenter="hoverActive(0)"
                  >
                    <q-icon class="icontest" size="40px">
                      <img
                        src="../../assets/icons/arrow-left-24.svg"
                        alt="arrow"
                      />
                    </q-icon>
                    Developers
                  </p>
                  <p
                    :class="[{ titleTestActive: hover1 }, titleTest]"
                    @mouseenter="hoverActive(1)"
                  >
                    <q-icon class="icontest" size="40px">
                      <img
                        src="../../assets/icons/arrow-left-24.svg"
                        alt="arrow"
                      />
                    </q-icon>
                    UX | UI design
                  </p>
                  <p
                    :class="[{ titleTestActive: hover2 }, titleTest]"
                    @mouseenter="hoverActive(2)"
                  >
                    <q-icon class="icontest" size="40px">
                      <img
                        src="../../assets/icons/arrow-left-24.svg"
                        alt="arrow"
                      />
                    </q-icon>
                    Sales and Marketing
                  </p>
                  <p
                    :class="[{ titleTestActive: hover3 }, titleTest]"
                    @mouseenter="hoverActive(3)"
                  >
                    <q-icon class="icontest" size="40px">
                      <img
                        src="../../assets/icons/arrow-left-24.svg"
                        alt="arrow"
                      />
                    </q-icon>
                    Data mining
                  </p>
                </div>
              </div>
            </div>
            <div class="rigthGridxs">
              <!-- <div class="backgroundCarousel">
                  <img
                    v-if="hover"
                    src="../../assets/Backgrounds/Developers.svg"
                    alt="developvers"
                    class="imgBackground"
                  />

                  <img
                    v-if="hover1"
                    src="../../assets/Backgrounds/UXUI.svg"
                    alt="developvers"
                    class="imgBackground"
                  />

                  <img
                    v-if="hover2"
                    src="../../assets/Backgrounds/Marketing.svg"
                    alt="developvers"
                    class="imgBackground"
                  />

                  <img
                    v-if="hover3"
                    src="../../assets/Backgrounds/Data.svg"
                    alt="developvers"
                    class="imgBackground"
                  />
                </div> -->
              <div style="position: relative">
                <div class="title">
                  <div class="rectangle">
                    <img
                      src="../../assets/icons/RectangleOrange.svg"
                      alt="orange"
                    />
                  </div>
                  300dev gold <br />
                  team
                </div>
                <div class="divBeforeOptions">
                  <p
                    :class="[{ titleTestActive: hover }, titleTest]"
                    @click="hoverActive(0)"
                  >
                    <q-icon class="icontest" size="40px">
                      <img
                        src="../../assets/icons/arrow-left-24.svg"
                        alt="arrow"
                      />
                    </q-icon>
                    Developers
                  </p>
                  <p
                    :class="[{ titleTestActive: hover1 }, titleTest]"
                    @click="hoverActive(1)"
                  >
                    <q-icon class="icontest" size="40px">
                      <img
                        src="../../assets/icons/arrow-left-24.svg"
                        alt="arrow"
                      />
                    </q-icon>
                    UX | UI design
                  </p>
                  <p
                    :class="[{ titleTestActive: hover2 }, titleTest]"
                    @click="hoverActive(2)"
                  >
                    <q-icon class="icontest" size="40px">
                      <img
                        src="../../assets/icons/arrow-left-24.svg"
                        alt="arrow"
                      />
                    </q-icon>
                    Sales and Marketing
                  </p>
                  <p
                    :class="[{ titleTestActive: hover3 }, titleTest]"
                    @click="hoverActive(3)"
                  >
                    <q-icon class="icontest" size="40px">
                      <img
                        src="../../assets/icons/arrow-left-24.svg"
                        alt="arrow"
                      />
                    </q-icon>
                    Data mining
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lottieData from '../../assets/animation/chek-icon.json';
import Lottie from 'vue-lottie-web';
import Lottie1 from 'vue-lottie';
import animationData from '../../assets/animation/GotaOrange.json';
import animationData2 from '../../assets/animation/responsive/GotaOrange.json';
import { scrollFunction } from '../../utils/scroll';
export default {
  name: 'Team',
  components: {
    Lottie,
    Lottie1
  },
  props: {
    scrollTeam: Boolean,
    scrollServices: Boolean,
    activeTeam: Boolean,
    activeTeamxs: Boolean,
    activeTeamsm: Boolean
  },
  data() {
    return {
      array: [
        {
          id: 0,
          name: 'Leonardo Gomez',
          toMake: 'Project Manager',
          img:
            'https://firebasestorage.googleapis.com/v0/b/dev-dd6f7.appspot.com/o/Team%2Fleonardo.svg?alt=media&token=1f01202c-453d-47e3-9553-c7abced05eee',
          img1:
            'https://firebasestorage.googleapis.com/v0/b/dev-dd6f7.appspot.com/o/Team%2Fandres.svg?alt=media&token=0daa7ac0-8c17-42e8-8d81-9eba267c5a80'
        },
        {
          id: 1,
          name: 'Andrés García',
          toMake: 'Front End Developer',
          img:
            'https://firebasestorage.googleapis.com/v0/b/dev-dd6f7.appspot.com/o/Team%2Fandres.svg?alt=media&token=0daa7ac0-8c17-42e8-8d81-9eba267c5a80',
          img1:
            'https://firebasestorage.googleapis.com/v0/b/dev-dd6f7.appspot.com/o/Team%2Fgustavo.svg?alt=media&token=3e05d184-ad85-4539-a29a-a8112c0c3194'
        },
        {
          id: 2,
          name: 'Gustavo Cotaina',
          toMake: 'Lead UX UI Designer',
          img:
            'https://firebasestorage.googleapis.com/v0/b/dev-dd6f7.appspot.com/o/Team%2Fgustavo.svg?alt=media&token=3e05d184-ad85-4539-a29a-a8112c0c3194',
          img1:
            'https://firebasestorage.googleapis.com/v0/b/dev-dd6f7.appspot.com/o/Team%2Fteamn.svg?alt=media&token=0a2afdb4-99f7-4117-b12b-a63fc4bebf3f'
        },
        {
          id: 3,
          name: 'María López',
          toMake: 'Front End Developer',
          img:
            'https://firebasestorage.googleapis.com/v0/b/dev-dd6f7.appspot.com/o/Team%2Fteamn.svg?alt=media&token=0a2afdb4-99f7-4117-b12b-a63fc4bebf3f',
          img1:
            'https://firebasestorage.googleapis.com/v0/b/dev-dd6f7.appspot.com/o/Team%2Fjimena.svg?alt=media&token=5048981b-fc55-4a1b-b2e1-c36ac106ff9f'
        },
        {
          id: 4,
          name: 'Jimena Francis',
          toMake: 'Backend Developer',
          img:
            'https://firebasestorage.googleapis.com/v0/b/dev-dd6f7.appspot.com/o/Team%2Fjimena.svg?alt=media&token=5048981b-fc55-4a1b-b2e1-c36ac106ff9f',
          img1:
            'https://firebasestorage.googleapis.com/v0/b/dev-dd6f7.appspot.com/o/Team%2Fana.svg?alt=media&token=1f09b232-10d6-426d-9d49-a852a403b692'
        },
        {
          id: 5,
          name: 'Ana Rojas',
          toMake: 'Server Engineer Developer',
          img:
            'https://firebasestorage.googleapis.com/v0/b/dev-dd6f7.appspot.com/o/Team%2Fana.svg?alt=media&token=1f09b232-10d6-426d-9d49-a852a403b692',
          img1:
            'https://firebasestorage.googleapis.com/v0/b/dev-dd6f7.appspot.com/o/Team%2Fgabriel.svg?alt=media&token=395fd66f-80bd-477d-9cfa-398924b3dbef'
        },
        {
          id: 6,
          name: 'Gabriel Pérez',
          toMake: 'Scrum Master Developer',
          img:
            'https://firebasestorage.googleapis.com/v0/b/dev-dd6f7.appspot.com/o/Team%2Fgabriel.svg?alt=media&token=395fd66f-80bd-477d-9cfa-398924b3dbef',
          img1:
            'https://firebasestorage.googleapis.com/v0/b/dev-dd6f7.appspot.com/o/Team%2Fleonardo.svg?alt=media&token=1f01202c-453d-47e3-9553-c7abced05eee'
        },
        {
          id: 7,
          name: 'Denisse Ramos',
          toMake: 'Product Owner Developer',
          img:
            'https://firebasestorage.googleapis.com/v0/b/dev-dd6f7.appspot.com/o/Team%2Fleonardo.svg?alt=media&token=1f01202c-453d-47e3-9553-c7abced05eee',
          img1:
            'https://firebasestorage.googleapis.com/v0/b/dev-dd6f7.appspot.com/o/Team%2Fandres.svg?alt=media&token=0daa7ac0-8c17-42e8-8d81-9eba267c5a80'
        }
      ],
      lottieData,
      lottieInstance: '',
      lottieInstance2: '',
      lottieInstance3: '',
      lottieInstance4: '',
      lottieInstance5: '',
      hover: true,
      hover1: false,
      hover2: false,
      hover3: false,
      titleTest: 'titleTest',
      titleTestActive: 'titleTestActive',
      varx: '',
      vary: '',
      colora: 'black',
      cuadrado1: true,
      cuadrado2: false,
      defaultOptions: {
        animationData: animationData,
        loop: false,
        autoplay: false
      },
      defaultOptions4: {
        animationData: animationData,
        loop: false,
        autoplay: true
      },
      defaultOptions1: { loop: false, autoplay: false },
      defaultOptions3: {
        animationData: animationData2,
        loop: false,
        autoplay: true
      }
    };
  },
  created: function () {
    scrollFunction(this.playAnimationTeam, this.stopAnimationTeam);
  },

  destroyed() {
    window.removeEventListener('scroll');
  },
  methods: {
    getLottieInstance(lottieInstance) {
      this.lottieInstance = lottieInstance;
    },
    getLottieInstance2(lottieInstance2) {
      this.lottieInstance2 = lottieInstance2;
    },
    getLottieInstance3(lottieInstance3) {
      this.lottieInstance3 = lottieInstance3;
    },
    getLottieInstance4(lottieInstance4) {
      this.lottieInstance4 = lottieInstance4;
    },
    getLottieInstance5(lottieInstance5) {
      this.lottieInstance5 = lottieInstance5;
    },
    playAnimation(n) {
      if (n == 1) {
        this.lottieInstance && this.lottieInstance.play();
      }
      if (n == 2) {
        this.lottieInstance2 && this.lottieInstance2.play();
      }
      if (n == 3) {
        this.lottieInstance3 && this.lottieInstance3.play();
      }
      if (n == 4) {
        this.lottieInstance4 && this.lottieInstance4.play();
      }
      if (n == 5) {
        this.lottieInstance5 && this.lottieInstance5.play();
      }
    },
    PauseAnimation(n) {
      if (n == 1) {
        this.lottieInstance && this.lottieInstance.pause();
      }
      if (n == 2) {
        this.lottieInstance2 && this.lottieInstance2.pause();
      }
      if (n == 3) {
        this.lottieInstance3 && this.lottieInstance3.pause();
      }
      if (n == 4) {
        this.lottieInstance4 && this.lottieInstance4.pause();
      }
      if (n == 5) {
        this.lottieInstance5 && this.lottieInstance5.pause();
      }
    },
    hoverActive(n) {
      if (n == 0) {
        this.hover = true;
        this.hover1 = false;
        this.hover2 = false;
        this.hover3 = false;
      }
      if (n == 1) {
        this.hover1 = true;
        this.hover = false;
        this.hover2 = false;
        this.hover3 = false;
      }
      if (n == 2) {
        this.hover2 = true;
        this.hover1 = false;
        this.hover = false;
        this.hover3 = false;
      }
      if (n == 3) {
        this.hover3 = true;
        this.hover1 = false;
        this.hover2 = false;
        this.hover = false;
      }
    },
    MoveMouse(e) {
      this.varx = `${e.layerX + 35}px`;
      this.vary = `${e.layerY - 220}px`;
    },
    TestCuadradoHover() {
      this.cuadrado1 = false;
      this.cuadrado2 = true;
    },
    TestCuadradoHoverNot() {
      this.cuadrado1 = true;
      this.cuadrado2 = false;
    },
    handleAnimation: function (anim) {
      this.anim = anim;
    },
    playAnimationTeam() {
      this.anim.play();
    },
    stopAnimationTeam() {
      this.anim.pause();
    }
  }
};
</script>
<style lang="stylus" scoped>
.main {
  min-height: 100vh;
  background-color: #FF6600;
  padding-top: 2rem;
}

.rectangle {
  padding-right: 10px;
}

.text {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.title {
  width: 80%;
  display: flex;
  margin-top 3rem;
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 55px;
  color: #FFFFFF;
}

.brxs {
  display: none;
}

.brsm {
  display: none;
}

.divBackground {
  min-width: 100%;
  position: absolute;
}

.grid {
  width: 100%;
  margin: auto;
}

.subTitle {
  width: 80%;
  display: flex;
  margin-top: 1rem;
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  color: #FFFFFF;
}

.checkSection {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #FFFFFF;
}

.titleGrid {
  padding-top: 0.5rem;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: #FFFFFF;
}

.rigthGrid {
  display: none;
}

.rigthGridxs {
  width: 90%;
  height: 100%;
}

.gridpapa {
  margin-top: 5.5rem
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gridTest {
  width: 100%;
}

.icontest {
  visibility: hidden;
  opacity: 0;
  transition: visibility 2s, opacity 1s linear;
}

.titleTest {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 55px;
  color: #474B4E;
  margin-left: -1rem;
  transition: color 2s;
  transition: margin-left 1s ease;

  &:hover {
    cursor: pointer;
  }
}

.titleTestActive {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 55px;
  color: #F2F2F2;
  margin-left: 2rem;

  .icontest {
    visibility: visible;
    opacity: 1;
  }

  &:hover {
    cursor: pointer;
  }
}

.divBeforeGridTest {
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 3rem;
  min-height: 30vh !important;
  background: #333333;
}

.divBeforeImage {
  min-height: 455px;
}

.divBeforeOptions {
  margin-top: 6rem;
  padding-bottom: 1rem;
}

.svgResponsive {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.backgroundCarousel {
  position: absolute;
  width: 90%;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgBackground {
  width: 80%;
  opacity: 0.4;
}

.animation1 {
  display: none;
}

.animationsm {
  display: none;
}

.animationxs {
  width: 100%;
  margin-top: -1rem;
}

.team-check {
  width: 150px;
}

.team-container {
  display: flex;
}

.grid {
  width: 80%
}

@media (max-width: 450px) {
  .title {
    margin-top: 2rem
  }

  .text {
    margin-bottom: 2rem
  }

  .divBeforeGridTest {
    padding-top: 0rem
  }
}


@media (min-width: 451px) and (max-width: 530px) {
  .main {
    min-height: 200vh;
    background-color: #FF6600;
    margin-top: -2rem;
  }

  .brxs {
    display: none;
  }

  .brsm {
    display: none;
  }

  .divBackground {
    min-width: 100%;
    position: absolute;
  }

  .grid {
    width: 100%;
    margin: auto;
  }

  .title {
    font-size: 32px;
    line-height: 64px;
  }

  .subTitle {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 45px;
    color: #FFFFFF;
  }

  .checkSection {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #FFFFFF;
    margin: 1rem;
  }

  .titleGrid {
    padding-top: 0.5rem;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
  }

  .rigthGrid {
    display: none;
  }

  .rigthGridxs {
    width: 90%;
    height: 100%;
  }

  .gridpapa {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .gridTest {
    width: 100%;
  }

  .icontest {
    visibility: hidden;
    opacity: 0;
    transition: visibility 2s, opacity 1s linear;
  }

  .titleTest {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 35px;
    color: #474B4E;
    margin-left: -1rem;
    transition: color 2s;
    transition: margin-left 1s ease;

    &:hover {
      cursor: pointer;
    }
  }

  .titleTestActive {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 35px;
    color: #FFFFFF;
    margin-left: 0.5rem;

    .icontest {
      visibility: visible;
      opacity: 1;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .divBeforeGridTest {
    margin-top: 5rem;
    height: 160vh;
    background: #333333;
  }

  .divBeforeImage {
    min-height: 537px;
  }

  .divBeforeOptions {
    margin-top: 2rem;
  }

  .svgResponsive {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .backgroundCarousel {
    position: absolute;
    width: 90%;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imgBackground {
    width: 80%;
    opacity: 0.4;
  }

  .animation1 {
    display: none;
  }

  .animationsm {
    display: none;
  }

  .animationxs {
    width: 100%;
    margin-top: -1rem;
  }
}

@media (min-width: 531px) and (max-width: 600px) {
  .main {
    min-height: 200vh;
    background-color: #FF6600;
    margin-top: -2rem;
  }

  .brxs {
    display: none;
  }

  .brsm {
    display: none;
  }

  .divBackground {
    min-width: 100%;
    position: absolute;
  }

  .grid {
    width: 100%;
    margin: auto;
  }

  .title {
    font-size: 32px;
    line-height: 64px;
  }

  .subTitle {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 45px;
    color: #FFFFFF;
  }

  .checkSection {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #FFFFFF;
    margin: 1rem;
  }

  .titleGrid {
    padding-top: 0.5rem;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
  }

  .rigthGrid {
    display: none;
  }

  .rigthGridxs {
    width: 90%;
    height: 100%;
  }

  .gridpapa {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .gridTest {
    width: 100%;
  }

  .icontest {
    visibility: hidden;
    opacity: 0;
    transition: visibility 2s, opacity 1s linear;
  }

  .titleTest {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 35px;
    color: #474B4E;
    margin-left: -1rem;
    transition: color 2s;
    transition: margin-left 1s ease;

    &:hover {
      cursor: pointer;
    }
  }

  .titleTestActive {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 35px;
    color: #FFFFFF;
    margin-left: 0.5rem;

    .icontest {
      visibility: visible;
      opacity: 1;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .divBeforeGridTest {
    margin-top: 5rem;
    height: 190vh;
    background: #333333;
  }

  .divBeforeImage {
    min-height: 603px;
  }

  .divBeforeOptions {
    margin-top: 2rem;
  }

  .svgResponsive {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .backgroundCarousel {
    position: absolute;
    width: 90%;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imgBackground {
    width: 80%;
    opacity: 0.4;
  }

  .animationxs {
    display: none;
  }

  .animationsm {
    display: none;
  }

  .animation1 {
    width: 100%;
    margin-top: -1rem;
  }
}

@media (min-width: 601px) and (max-width: 800px) {
  .main {
    min-height: 200vh;
    background-color: #FF6600;
    margin-top: -2rem;
  }

  .brxs {
    display: none;
  }

  .brsm {
    display: none;
  }

  .divBackground {
    min-width: 100%;
    position: absolute;
  }

  .grid {
    width: 100%;
    margin: auto;
  }

  .title {
    font-size: 32px;
    line-height: 64px;
  }

  .subTitle {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 45px;
    color: #FFFFFF;
  }

  .checkSection {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #FFFFFF;
    margin: 1rem;
  }

  .titleGrid {
    padding-top: 0.5rem;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
  }

  .rigthGrid {
    display: none;
  }

  .rigthGridxs {
    width: 90%;
    height: 100%;
  }

  .gridpapa {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .gridTest {
    width: 100%;
  }

  .icontest {
    visibility: hidden;
    opacity: 0;
    transition: visibility 2s, opacity 1s linear;
  }

  .titleTest {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 35px;
    color: #474B4E;
    margin-left: -1rem;
    transition: color 2s;
    transition: margin-left 1s ease;

    &:hover {
      cursor: pointer;
    }
  }

  .titleTestActive {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 35px;
    color: #FFFFFF;
    margin-left: 0.5rem;

    .icontest {
      visibility: visible;
      opacity: 1;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .divBeforeGridTest {
    height: 100vh;
    background: #333333;
    padding-top: 0rem
  }

  .divBeforeImage {
    min-height: 805px;
  }

  .divBeforeOptions {
    margin-top: 2rem;
  }

  .svgResponsive {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .backgroundCarousel {
    position: absolute;
    width: 90%;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imgBackground {
    width: 80%;
    opacity: 0.4;
  }

  .animationxs {
    display: none;
  }

  .animation1 {
    display: none;
  }

  .animationsm {
    display: block;
    width: 100%;
    margin-top: -1rem;
  }

  .text {
    padding-bottom: 6rem;
  }
}

@media (min-width: 800px) and (max-width: 1023px) {
  .main {
    min-height: 200vh;
    background-color: #FF6600;
    margin-top: -2rem;
  }

  .brxs {
    display: block;
  }

  .brsm {
    display: none;
  }

  .divBackground {
    min-width: 100%;
    position: absolute;
  }

  .grid {
    width: 100%;
    margin: auto;
  }

  .title {
    font-size: 32px;
    line-height: 64px;
  }

  .subTitle {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 45px;
    color: #FFFFFF;
  }

  .checkSection {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #FFFFFF;
    margin: 1rem;
  }

  .titleGrid {
    padding-top: 0.5rem;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
  }

  .rigthGrid {
    display: none;
  }

  .rigthGridxs {
    width: 90%;
    height: 100%;
  }

  .gridpapa {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .gridTest {
    width: 100%;
  }

  .icontest {
    visibility: hidden;
    opacity: 0;
    transition: visibility 2s, opacity 1s linear;
  }

  .titleTest {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 35px;
    color: #474B4E;
    margin-left: -1rem;
    transition: color 2s;
    transition: margin-left 1s ease;

    &:hover {
      cursor: pointer;
    }
  }

  .titleTestActive {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 35px;
    color: #FFFFFF;
    margin-left: 0.5rem;

    .icontest {
      visibility: visible;
      opacity: 1;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .divBeforeGridTest {
    margin-top: 5rem;
    height: 250vh;
    background: #333333;
  }

  .divBeforeImage {
    min-height: 1030px;
  }

  .divBeforeOptions {
    margin-top: 2rem;
  }

  .svgResponsive {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .backgroundCarousel {
    position: absolute;
    width: 90%;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imgBackground {
    width: 50%;
    opacity: 0.4;
  }

  .animationxs {
    display: none;
  }

  .animation1 {
    display: none;
  }

  .animationsm {
    display: block;
    width: 100%;
    margin-top: -1rem;
  }
}

@media (min-width: 1024px) and (max-width: 1300px) {
  .main {
    min-height: 80vh;
    background-color: #FF6600;
    margin-top: -0.1rem;
  }

  .divBackground {
    min-width: 100%;
    position: absolute;
  }

  .grid {
    width: 100%;
    margin: auto;
  }

  .brxs {
    display: block;
  }

  .brsm {
    display: block;
  }

  .title {
    font-size: 50px;
    line-height: 64px;
  }

  .subTitle {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 45px;
    color: #FFFFFF;
  }

  .checkSection {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
    margin: 1rem;
  }

  .titleGrid {
    padding-top: 1.5rem;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
  }

  .rigthGrid {
    display: block;
    width: 80%;
  }

  .rigthGridxs {
    display: none;
  }

  .gridpapa {
    width: 100%;
    height: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .gridTest {
    width: 100%;
  }

  .icontest {
    visibility: hidden;
    opacity: 0;
    transition: visibility 2s, opacity 1s linear;
  }

  .titleTest {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    color: #474B4E;
    margin-left: -2rem;
    transition: color 2s;
    transition: margin-left 1s ease;

    &:hover {
      cursor: pointer;
    }
  }

  .titleTestActive {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    color: #FFFFFF;
    margin-left: 0.5rem;

    .icontest {
      visibility: visible;
      opacity: 1;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .divBeforeGridTest {
    margin-top: 10rem;
    background: #333333;
  }

  .divBeforeImage {
    min-height: 800px;
  }

  .divBeforeOptions {
    margin-top: 4rem;
  }

  .animationxs {
    display: none;
  }

  .animationsm {
    display: none;
  }

  .animation1 {
    display: block;
    width: 100%;
    margin-top: -1rem;
  }
}

@media (min-width: 1300px) {
  .main {
    min-height: 100vh;
    background-color: #FF6600;
    margin-top: -0.1rem;
  }

  .divBackground {
    min-width: 100%;
    position: absolute;
  }

  .brxs {
    display: block;
  }

  .brsm {
    display: block;
  }

  .grid {
    width: 100%;
    margin: auto;
  }

  .title {
    margin-top: 6rem;
    font-size: 50px;
    line-height: 64px;
  }

  .subTitle {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 45px;
    color: #FFFFFF;
  }

  .checkSection {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
    margin: 1rem;
  }

  .titleGrid {
    padding-top: 1.5rem;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
  }

  .rigthGrid {
    display: block;
    width: 75%;
  }

  .rigthGridxs {
    display: none;
  }

  .gridpapa {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .gridTest {
    width: 100%;
  }

  .icontest {
    visibility: hidden;
    opacity: 0;
    transition: visibility 2s, opacity 1s linear;
  }

  .titleTest {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    color: #474B4E;
    margin-left: -2rem;
    transition: color 2s;
    transition: margin-left 1s ease;

    &:hover {
      cursor: pointer;
    }
  }

  .titleTestActive {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    color: #FFFFFF;
    margin-left: 0.5rem;

    .icontest {
      visibility: visible;
      opacity: 1;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .divBeforeGridTest {
    margin-top: 20rem;
    background: #333333;
  }

  .divBeforeImage {
    min-height: 800px;
  }

  .divBeforeOptions {
    margin-top: 4rem;
  }

  .animationxs {
    display: none;
  }

  .animationsm {
    display: none;
  }

  .animation1 {
    display: block;
    width: 100%;
    padding-bottom: 1rem;
  }

  .text {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
  }

}

@media (min-width: 1024px) and (max-height: 800px) {
  .divBeforeGridTest {
    position: relative;
    margin-top: 0rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  .gridpapa {
    margin-top: 0rem;
  }

  .main {
    padding-top: 0rem;
  }

  .text {
    padding-bottom: 6rem;
  }
}
</style>

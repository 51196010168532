<template>
  <q-drawer
    v-model="activeDrawer"
    :breakpoint="600"
    :width="320"
    overlay
    side="right"
    class="q-drawerMd"
  >
    <q-card square flat class="qcardDrawer">
      <div class="divBackgroundDrawer">
        <Lottie1 class="animation-one" :options="defaultOptions" />
      </div>

      <div class="textDrawer">
        <div class="drawer">
          <img alt="logo" src="../../assets/logo.svg" class="logo" />
          <q-btn flat class="closeDrawer" icon="close" @click="closeDrawer()" />
        </div>

        <div class="btnsDrawer">
          <router-link
            v-for="newSection in newSections"
            :key="newSection.name"
            class="href"
            v-scroll-to="{
              el: newSection.scrollTo,
              onStart: function (element) {
                closeDrawer();
              }
            }"
            :to="newSection.to"
          >
            <q-btn
              flat
              :class="[newSection.active ? 'btnDrawerWhite' : 'btnDrawer']"
              no-caps
            >
              <img
                :class="[
                  newSection.active
                    ? 'rectangleDrawerActive'
                    : 'rectangleDrawer'
                ]"
                src="../../assets/icons/icon-rectangleorangexs.svg"
                alt="orangexs"
              />
              {{ newSection.name }}
            </q-btn>
          </router-link>

          <q-btn class="buttonContactDrawer" no-caps @click="closeContact()">
            Contact Us
          </q-btn>
        </div>
      </div>
    </q-card>
  </q-drawer>
</template>

<script>
import Lottie1 from 'vue-lottie';
import animationData from '../../assets/animation/responsive/Menu.json';
export default {
  name: 'Drawer',
  components: {
    Lottie1
  },
  props: {
    scrollActive: {
      type: Object,
      default: undefined
    },
    alert: {
      type: Boolean,
      default: false
    },
    drawer: {
      type: Boolean,
      default: false
    },
    openContact: {
      type: Function,
      default: () => {}
    },
    closeDrawer: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      defaultOptions: {
        animationData: animationData,
        loop: false,
        autoplay: true
      },
      sections: [
        {
          name: 'Home',
          scrollTo: '#home',
          to: '#',
          active: undefined
        },
        {
          name: 'Services',
          scrollTo: '#services',
          to: '#services',
          active: undefined
        },
        {
          name: 'Technologies',
          scrollTo: '#technologies',
          to: '#technologies',
          active: undefined
        },
        {
          name: 'Team',
          scrollTo: '#team',
          to: '#team',
          active: undefined
        },
        {
          name: 'Get Started',
          scrollTo: '#started',
          to: '#started',
          active: undefined
        }
      ],
      showDrawer: false
    };
  },
  computed: {
    newSections: function () {
      const activeSection = Object.values({ ...this.scrollActive });
      const newSections = this.sections.map((section, i) => {
        section.active = activeSection[i];
        return section;
      });
      return newSections;
    },
    activeDrawer: function () {
      return this.drawer;
    }
  }
};
</script>

<style lang="stylus" scoped>
.qcardDrawer {
  min-height: 100vh;
  background-color: #333333;
}

.divBackgroundDrawer {
  min-height: 150vh;
  min-width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.animation-one {
  width: 85%
}

.drawer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  // padding-left: 2rem;
  position: relative;
  z-index: 9;
}

.closeDrawer {
    color: #FFFFFF;
    font-size: 16px;
}

.rectangleDrawer {
  display: none;
}

.rectangleDrawerActive {
  display: block;
  margin-right: 1rem;
}

.buttonContactDrawer {
  width: 60%;
  margin: 1rem;
  color: white;
  background-color: #FF6600;
  padding: 5px 20px;
}

.btnDrawerWhite {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 44px;
  color: #FFFFFF;
  margin-right: 2rem;
}

.btnsDrawer {
  width: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btnDrawer {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 44px;
  color: #979797;
  text-decoration: none !important;
}

.textDrawer {
  position: relative;
}

.href {
  color: black;
  text-decoration: none;
}

@media (max-width: 600px) and (min-height: 400px) and (max-height: 500px) {
  .divBackgroundDrawer {
    min-height: 120vh;
  }
}

@media (max-width: 600px) and (min-height: 500px) {
  .divBackgroundDrawer {
    min-height: 100vh;
  }
}

@media (min-width: 601px) and (max-width 1015px) {
  .btnsDrawer {
    // display: block;
    min-height: 70vh;
  }

  .buttonContactDrawer {
    display: none;
  }

  .btnDrawerWhite {
    text-decoration: none !important;
  }
}

@media (min-width: 1015px) {
  .q-drawerMd {
    display: none;
  }

  .divBackgroundDrawer {
    min-height: 100vh;
  }

  .buttonContactDrawer {
    display: none;
  }
}
</style>

<template>
  <div class="main">
    <div class="divBackground">
      <Lottie
        class-name="animation"
        renderer="canvas"
        :loop="false"
        :autoplay="true"
        :animation-data="lottieData"
      />
      <lottie1 class="animationxs" :options="defaultOptions" />
    </div>

    <div class="text">
      <p class="title">
        Your developers, <br />
        ready!
      </p>
      <p class="subtitle">
        We are software developers that help you build solutions from
        <br class="brxs" />
        marketing websites, mobile apps, and eCommerce to Cloud
        <br class="brxs" />
        Server Migration, Management, and AI model development.
      </p>
      <router-link v-scroll-to="'#services'" class="href" to="#services">
        <!-- <img class="iconArrowAnimation" style="margin-top : 4rem" src="../../assets/icons/icon-arrowMain.svg" alt="arrowMain"> -->
        <Lottie
          class-name="animationArrow"
          renderer="canvas"
          :loop="true"
          :autoplay="true"
          :animation-data="lottieData1"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import lottieData from '../../assets/animation/Development.json';
import lottieData1 from '../../assets/animation/Arrow.json';
import lottieData2 from '../../assets/animation/responsive/Development.json';
import Lottie1 from 'vue-lottie';
import Lottie from 'vue-lottie-web';
export default {
  name: 'Main',
  components: {
    Lottie,
    Lottie1
  },
  data() {
    return {
      show: true,
      lottieData,
      lottieData1,
      lottieData2,
      lottieInstance: '',
      defaultOptions: {
        animationData: lottieData2,
        loop: false,
        autoplay: true
      }
    };
  }
};
</script>

<style lang="stylus" scoped>
@media (max-width: 480px) and (max-height: 400px) {
  .brxs {
    display: none;
  }

  .main {
    min-height: 100vh;
    background-color: #333333;
  }

  .divBackground {
    width: 100%;
    position: absolute;
    min-height: 110vh;
    display: flex;
    align-items: center;
  }

  .text {
    position: relative;
    height: 170vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: #FFFFFF;
  }

  .subtitle {
    width: 95%;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
  }

  .animation {
    display: none;
  }

  .animationxs {
    width: 50%;
  }

  .animationArrow {
    width: 50%;
    margin: auto;
  }
}

@media (max-width: 480px) and (min-height: 400px) {
  .brxs {
    display: none;
  }

  .main {
    min-height: 100vh;
    background-color: #333333;
  }

  .divBackground {
    width: 100%;
    position: absolute;
    min-height: 110vh;
    display: flex;
    align-items: center;
  }

  .text {
    position: relative;
    height: 110vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title {
    width: 90%
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 64px;
    text-align: center;
    color: #FFFFFF;
  }

  .subtitle {
    width: 70%;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
  }

  .animation {
    display: none;
  }

  .animationxs {
    width: 50%;
  }

  .animationArrow {
    width: 50%;
    margin: auto;
  }
}

@media (max-width: 400px) and (max-height: 580px) {
  .title {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: #FFFFFF;
  }

  .subtitle {
    width: 95%;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
  }
}

@media (min-width: 480px) and (max-width: 600px) and (max-height: 400px) {
  .brxs {
    display: none;
  }

  .main {
    min-height: 120vh;
    background-color: #333333;
  }

  .divBackground {
    width: 100%;
    position: absolute;
    min-height: 100vh;
    display: flex;
    align-items: center;
  }

  .text {
    position: relative;
    height: 220vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: #FFFFFF;
  }

  .subtitle {
    width: 95%;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
  }

  .animation {
    display: none;
  }

  .animationxs {
    width: 50%;
  }

  .animationArrow {
    width: 50%;
    margin: auto;
  }
}

@media (min-width: 480px) and (max-width: 600px) and (min-height: 400px) {
  .brxs {
    display: none;
  }

  .main {
    min-height: 120vh;
    background-color: #333333;
  }

  .divBackground {
    width: 100%;
    position: absolute;
    min-height: 100vh;
    display: flex;
    align-items: center;
  }

  .text {
    position: relative;
    height: 130vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: #FFFFFF;
  }

  .subtitle {
    width: 95%;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
  }

  .animation {
    display: none;
  }

  .animationxs {
    width: 50%;
  }

  .animationArrow {
    width: 50%;
    margin: auto;
  }
}

@media (min-width: 601px) and (max-height: 500px) {
  .main {
    min-height: 120vh;
    background-color: #333333;
  }

  .divBackground {
    width: 100%;
    min-height: 100vh;
    position: absolute;
    display: flex;
    align-items: center;
  }

  .text {
    position: relative;
    height: 180vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 64px;
    text-align: center;
    color: #FFFFFF;
  }

  .subtitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
  }

  .animation {
    width: 100%;
  }

  .animationxs {
    display: none !important;
  }

  .animationArrow {
    width: 70%;
    margin: auto;
  }
}

@media (min-width: 601px) and (min-height: 500px) {
  .main {
    min-height: 120vh;
    background-color: #333333;
  }

  .divBackground {
    width: 100%;
    min-height: 100vh;
    position: absolute;
    display: flex;
    align-items: center;
  }

  .text {
    position: relative;
    height: 110vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 64px;
    text-align: center;
    color: #FFFFFF;
  }

  .subtitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
  }

  .animation {
    width: 100%;
  }

  .animationxs {
    display: none !important;
  }

  .animationArrow {
    width: 70%;
    margin: auto;
  }
}
</style>

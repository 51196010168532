<template>
  <div style="width: 100%" class="main">
    <div style="width: 100% !important" class="divBackground">
      <div class="backgroundSrill">
        <img
          style="width: 80%"
          src="../../assets/Backgrounds/SKILLS.svg"
          alt="skills"
        />
      </div>
    </div>

    <div class="text">
      <div class="title">
        <div class="rectangle">
          <img src="../../assets/icons/RectangleOrange.svg" alt="blue" />
        </div>
        Want to know which technologies <br class="brxs" />
        are we using?
      </div>

      <p class="subtitle">
        We empower digital transformations and create quality digital
        <br class="brxs" />
        products with innovative technologies and emerging trends.
      </p>
      <!-- Team nuevo  -->
      <div
        style="margin-bottom: 10rem"
        class="grid row"
        :class="{
          'q-col-gutter-xs': $q.screen.xs,
          'q-col-gutter-md': $q.screen.gt.xs
        }"
      >
        <div class="col-xs-6 col-sm-6 col-md-3">
          <div class="itemTecno">
            <img class="imgIcon" :src="arrayIcons[0].icon" alt="html5" />
            <p class="textOnIcon">
              <span class="titleIcons">HTML5</span> is the latest <br />
              evolution of the <br />
              standard that defines <br />
              HTML (Hypertext <br />
              Markup Language).
            </p>
          </div>
        </div>

        <div class="col-xs-6 col-sm-6 col-md-3">
          <div class="itemTecno">
            <img class="imgIcon" :src="arrayIcons[1].icon" alt="html5" />
            <p class="textOnIcon">
              <span class="titleIcons">CSS3</span>
              is the latest <br />
              evolution of the <br />
              standard that defines <br />
              CSS (Cascading Style <br />
              Sheets).
            </p>
          </div>
        </div>

        <div class="col-xs-6 col-sm-6 col-md-3">
          <div class="itemTecno">
            <img class="imgIcon" :src="arrayIcons[2].icon" alt="html5" />
            <p class="textOnIcon">
              <span class="titleIcons">JavaScript</span>
              is a <br />
              programming <br />
              language that allows <br />
              you to implement <br />
              complex features on <br />
              web pages.
            </p>
          </div>
        </div>

        <div class="col-xs-6 col-sm-6 col-md-3">
          <div class="itemTecno">
            <img class="imgIcon" :src="arrayIcons[3].icon" alt="html5" />
            <p class="textOnIcon">
              <span class="titleIcons">TypeScript</span>
              is a <br />
              language for <br />
              application-scale <br />
              JavaScript.
            </p>
          </div>
        </div>

        <div class="col-xs-6 col-sm-6 col-md-3">
          <div class="itemTecno">
            <img class="imgIcon" :src="arrayIcons[4].icon" alt="html5" />
            <p class="textOnIcon">
              <span class="titleIcons">React</span>
              is a JavaScript <br />
              library for building <br />
              user interfaces.
            </p>
          </div>
        </div>

        <div class="col-xs-6 col-sm-6 col-md-3">
          <div class="itemTecno">
            <img class="imgIcon" :src="arrayIcons[5].icon" alt="html5" />
            <p class="textOnIcon">
              <span class="titleIcons">Angular</span>
              is a platform <br />
              for building mobile <br />
              and desktop web <br />
              applications.
            </p>
          </div>
        </div>

        <div class="col-xs-6 col-sm-6 col-md-3">
          <div class="itemTecno">
            <img class="imgIcon" :src="arrayIcons[6].icon" alt="html5" />
            <p class="textOnIcon">
              <span class="titleIcons">Vue.js</span>
              is an <br />
              open-source <br />
              JavaScript framework <br />
              for building user <br />
              interfaces and <br />
              single-page <br />
              applications.
            </p>
          </div>
        </div>

        <div class="col-xs-6 col-sm-6 col-md-3">
          <div class="itemTecno">
            <img
              class="imgIcon imgIconLetters"
              :src="arrayIcons[7].icon"
              alt="html5"
            />
            <p class="textOnIcon">
              <span class="titleIcons">Node.js</span>
              is an <br />
              open-source, <br />
              backend, JS runtime <br />
              environment that <br />
              executes JavaScript <br />
              code outside a web <br />
              browser.
            </p>
          </div>
        </div>

        <div class="col-xs-6 col-sm-6 col-md-3">
          <div class="itemTecno">
            <img
              class="imgIcon imgIconLetters"
              :src="arrayIcons[8].icon"
              alt="html5"
            />
            <p class="textOnIcon">
              <span class="titleIcons">MongoDB</span>
              is an <br />
              document database <br />
              with the scalability <br />
              and flexibility that <br />
              you want with the <br />
              querying and <br />
              indexing that you <br />
              need
            </p>
          </div>
        </div>

        <div class="col-xs-6 col-sm-6 col-md-3">
          <div class="itemTecno">
            <img class="imgIcon" :src="arrayIcons[9].icon" alt="html5" />
            <p class="textOnIcon">
              <span class="titleIcons">MySQL</span>
              is a database <br />
              management system.
            </p>
          </div>
        </div>

        <div class="col-xs-6 col-sm-6 col-md-3">
          <div class="itemTecno">
            <img
              class="imgIcon imgIconLetters"
              :src="arrayIcons[10].icon"
              alt="html5"
            />
            <p class="textOnIcon">
              <span class="titleIcons">Firebase</span>
              is Google's <br />
              mobile platform that <br />
              helps you quickly <br />
              develop high-quality <br />
              apps and grow your <br />
              business.
            </p>
          </div>
        </div>

        <div class="col-xs-6 col-sm-6 col-md-3">
          <div class="itemTecno">
            <img class="imgIcon" :src="arrayIcons[11].icon" alt="html5" />
            <p class="textOnIcon">
              <span class="titleIcons">PostgreSQL</span>
              is a <br />
              powerful, open <br />
              source <br />
              object-relational <br />
              database system that <br />
              uses and extends the <br />
              SQL language
            </p>
          </div>
        </div>

        <div class="col-xs-6 col-sm-6 col-md-3">
          <div class="itemTecno">
            <img class="imgIcon" :src="arrayIcons[12].icon" alt="html5" />
            <p class="textOnIcon">
              <span class="titleIcons"
                >Amazon Web <br />
                Services
              </span>
              offers <br />
              reliable, scalable, and <br />
              inexpensive cloud <br />
              computing services.
            </p>
          </div>
        </div>

        <div class="col-xs-6 col-sm-6 col-md-3">
          <div class="itemTecno">
            <img class="imgIcon" :src="arrayIcons[13].icon" alt="html5" />
            <p class="textOnIcon">
              <span class="titleIcons">GCP</span>
              offers reliable, <br />
              scalable, and <br />
              inexpensive cloud <br />
              computing services.
            </p>
          </div>
        </div>

        <div class="col-xs-6 col-sm-6 col-md-3">
          <div class="itemTecno">
            <img
              class="imgIcon imgIconLetters"
              :src="arrayIcons[14].icon"
              alt="html5"
            />
            <p class="textOnIcon">
              <span class="titleIcons">Python</span>
              is an <br />
              interpreted, <br />
              object-oriented, <br />
              high-level <br />
              programming <br />
              language with <br />
              dynamic semantics.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Technologies',
  props: { scrollTechnologies: Boolean },
  data() {
    return {
      arrayIcons: [
        { icon: require('../../assets/iconblack/html5.svg') },
        { icon: require('../../assets/iconblack/css3.svg') },
        { icon: require('../../assets/iconblack/js.svg') },
        { icon: require('../../assets/iconblack/ts.svg') },
        { icon: require('../../assets/iconblack/react.svg') },
        { icon: require('../../assets/iconblack/angular.svg') },
        { icon: require('../../assets/iconblack/vue.svg') },
        { icon: require('../../assets/iconblack/nodejs.svg') },
        { icon: require('../../assets/iconblack/mongo.svg') },
        { icon: require('../../assets/iconblack/mysql.svg') },
        { icon: require('../../assets/iconblack/firebase.svg') },
        { icon: require('../../assets/iconblack/postgresql.svg') },
        { icon: require('../../assets/iconblack/aws.svg') },
        { icon: require('../../assets/iconblack/googlecloud.svg') },
        { icon: require('../../assets/iconblack/pyhton.svg') }
      ]
    };
  },
  methods: {}
};
</script>

<style lang="stylus" scoped>
.main {
  min-height: 150vh;
}

.divBackground {
  margin-top: -10rem;
  min-width: 100%;
  position: absolute;
}

.text {
  margin-top: 3rem;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  width 82%
  display: flex;
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 55px;
  color: #333333;
}

.rectangle {
  padding-right: 10px;
}

.subtitle {
  width: 80%;
  margin-top: 5.5rem;
  margin-bottom: 7rem;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.grid {
  margin-top: 1rem;
  width: 100%;
}

.titleGrid {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
}

.textGrid {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 28px;
  color: #333333;
}

.divSeeMore {
  display: flex;
  align-items: center;
}

.seeMore {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  color: #FF6600;
  transition: letter-spacing 0.5s;

  &:hover {
    letter-spacing: 2px;
    cursor: pointer;
  }
}

.imgArrow {
  margin-left: 1rem;
}

.containerOngrid {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titleIcon {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
}

.iconImg {
  display: none;
}

.icons {
  margin: 2rem;
  width: 80px;
}

.brxs {
  display: none;
}

.imgIcon {
  opacity: 1;
  transition: opacity 0.5s;
}

.imgIconLetters {
  width: 125px;
}

.textOnIcon {
  font-size: 12px;
  line-height: 18px;
  font-family: Montserrat;
  color: #FFFFFF;
  position: absolute;
  opacity: 0;
  transition: opacity 0.7s;
}

.itemTecno {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 180px;
  background-color: #ECECEC;
  transition: background-color 0.5s;

  &:hover {
    background-color: #111111;

    .textOnIcon {
      opacity: 1;
    }

    .imgIcon {
      opacity: 0;
    }
  }
}

.backgroundSrill {
  display: none;
  justify-content: center;
  margin-top: 90rem;
}

.titleIcons {
  color: #FFCA28;
  font-weight: bold;
}

.animationxs {
  width: 100%;
}

.animation {
  display: none;
}

.brxs {
  display: none;
}

@media (min-width: 601px) {
  .text {
    position: relative;
    width: 100%;
  }

  .title {
    font-size: 50px;
    line-height: 64px;
    text-align: center;
  }

  .subtitle {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
  }

  .grid {
    margin-top: 3rem;
    width: 80%;
    margin-bottom: 5rem;
  }

  .textGrid {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  }

  .seeMoreEgg {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .iconImg {
    margin-top: 2rem;
    display: flex;
    align-items: center;
  }

  .textOnIcon {
    font-size: 20px;
    line-height: 30px;
    position: absolute;
  }

  .itemTecno {
    height: 280px;
  }

  .backgroundSrill {
    display: flex;
    margin-top: 12rem;
  }

  .animation {
    display: block;
    width: 100%;
  }

  .animationxs {
    display: none;
  }

  .title {
    width 82%;
    justify-content: center;
  }

  .brxs {
    display: block;
  }
}
</style>

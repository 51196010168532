<template>
  <div class="container">
    <lottie1 :options="defaultOptions" />
  </div>
</template>

<script>
import Lottie1 from "vue-lottie";
import animationData from "../../assets/animation/Splash.json";
export default {
  name: "Splash",
  components: { Lottie1 },
  data() {
    return {
      defaultOptions: {
        animationData: animationData,
        loop: true,
        autoplay: true
      }
    };
  }
};
</script>
<style lang="stylus" scoped>
.container
    position : fixed
    z-index : 13
    width : 100vw
    height : 100vh
    background : rgba(249,249,249,0.5)
</style>

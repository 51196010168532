<template>
  <div class="main">
    <div class="divBackground">
      <Lottie1
        v-if="scrollGet"
        class="animation"
        style="width: 100%"
        :options="defaultOptions"
      />
      <Lottie1
        v-if="scrollGet"
        class="animationsm"
        style="width: 100%"
        :options="defaultOptions"
      />
      <Lottie1
        v-if="scrollGet"
        class="animationxs"
        style="width: 100%"
        :options="defaultOptions1"
      />
    </div>

    <div class="text">
      <div class="title">
        <div class="rectangle">
          <img src="../../assets/icons/RectangleOrange.svg" />
        </div>
        How to get <br />
        started with us
      </div>

      <div
        class="grid row q-col-gutter-xl"
        :class="{ 'text-center': $q.screen.lt.md }"
      >
        <div class="col-xs-12 col-md-4">
          <div class="stepNumBox">
            <img src="../../assets/noWingsOwl.svg" alt="owl" />
          </div>

          <p class="titleGrid">Contact Sales Team</p>

          <p class="textGrid">
            Get to know about our most <br />
            competitive IT solution plan and <br />
            price.
          </p>
          <div />
          <!-- <p class="seeMore"> See more  <img class="imgArrow" src="../../assets/icons/ArrowBlue.svg" alt="blue"></p> -->
        </div>

        <div
          class="grid col-xs-12 col-md-4"
          :class="{ 'q-my-lg': $q.screen.lt.md }"
        >
          <div class="stepNumBox">
            <img src="../../assets/oneWingOwl.svg" alt="owl" />
          </div>

          <p class="titleGrid">Receive our Quote</p>

          <p class="textGrid">
            After analyzing your business <br />
            requirenments, we’ll get back to <br />
            you with an estimations.
          </p>
          <div />
          <!-- <p class="seeMore"> See more  <img class="imgArrow" src="../../assets/icons/ArrowBlue.svg" alt="blue"></p> -->
        </div>

        <div class="col-xs-12 col-md-4" :class="{ 'q-my-lg': $q.screen.lt.md }">
          <div class="stepNumBox">
            <img src="../../assets/twoWingsOwl.svg" alt="owl" />
          </div>

          <p class="titleGrid">Let’s Get Started</p>

          <p class="textGrid">
            We do all the paper work, and <br />
            we’re all set to start working on <br />
            your business idea.
          </p>
          <div />
          <!-- <p class="seeMore"> 
                     See more  <img class="imgArrow" src="../../assets/icons/ArrowBlue.svg" alt="blue">
                </p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Lottie1 from 'vue-lottie';
import animationData from '../../assets/animation/GetStarted.json';
import animationData1 from '../../assets/animation//responsive/GetStarted.json';
export default {
  name: 'GetStarted',
  components: {
    Lottie1
  },
  props: { scrollGet: Boolean, scrollGetxs: Boolean },
  data() {
    return {
      array: ['Research Project', 'Targeting', 'Result'],
      defaultOptions: {
        animationData: animationData,
        loop: false,
        autoplay: true
      },
      defaultOptions1: {
        animationData: animationData1,
        loop: false,
        autoplay: true
      }
    };
  }
};
</script>

<style lang="stylus" scoped>
  .main {
    padding-top: 6rem;
    background-color: #333333
    min-height: 180vh;
  }

  .rectangle {
    padding-right: 10px;
  }

  .brxs {
    display: none;
  }

  .brsm {
    display: none;
  }

  .card {
    width: 45%;
    margin: auto;
    height: 50px;
    margin-top: -4rem;
    background-color: #333333;
    border-radius: 2px;
  }

  .divBackground {
    width: 100%;
    height: 80vh;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text {
    position: relative;
    width: 85%;
    margin: auto;
  }

  .title {
    display: flex;
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 55px;
    color: #FFFFFF;
  }

  .titleGrid {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
  }

  .titleGridTwo {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
  }

  .textGrid {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
  }

  .textGridTwo {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
  }

  .grid {
    padding-top: 4rem;
  }

  .stepNumBox {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
  }

  .stepIcon {
    background: #FF6600;
    width: 140px;
    height: 140px;
    position: relative;
    border: 3px solid #304FFE;
    border-radius: 50%;
    line-height: 140px;
    font-size: 50px;
    text-align: center;
    color: #ffffff;
    font-weight: 700;
  }

  .seeMore {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #304FFE;
    transition: letter-spacing 0.5s;

    &:hover {
      letter-spacing: 2px;
      cursor: pointer;
    }
  }

  .seeMoreTwo {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #304FFE;
    text-align: right;
    transition: letter-spacing 0.5s;

    &:hover {
      letter-spacing: 2px;
      cursor: pointer;
    }
  }

  .imgArrow {
    margin-left: 1rem;
  }

  .gridxs {
    display: none;
  }

  .gridCircle {
    display: flex;
    justify-content: flex-end;
  }

  .animation {
    display: none;
  }

  .animationsm {
    display: none;
  }

  .animationxs {
    width: 100%;
  }

@media (min-width: 601px) and (max-width: 1015px) {
  .main {
    min-height: 180vh;
    background-color: #333333;
    margin-top: -2rem;
  }

  .brxs {
    display: none;
  }

  .card {
    width: 45%;
    margin: auto;
    height: 50px;
    margin-top: -4rem;
    background-color: #333333;
    border-radius: 2px;
  }

  .divBackground {
    width: 100%;
    height: 170vh;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text {
    position: relative;
    width: 85%;
    margin: auto;
  }

  .title {
    padding-top: 6rem;
    display: flex;
    align-items: flex-start;
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 45px;
    color: #FFFFFF;
  }

  .titleGrid {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
  }

  .titleGridTwo {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
    text-align: right;
  }

  .textGrid {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
  }

  .textGridTwo {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
    text-align: right;
  }

  .grid {
    padding-top: 5rem;
  }

  .stepNumBox {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
  }

  .stepIcon {
    background: #FF6600;
    width: 140px;
    height: 140px;
    position: relative;
    border: 3px solid #304FFE;
    border-radius: 50%;
    line-height: 140px;
    font-size: 50px;
    text-align: center;
    color: #ffffff;
    font-weight: 700;
  }

  .seeMore {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #304FFE;
    transition: letter-spacing 0.5s;

    &:hover {
      letter-spacing: 2px;
      cursor: pointer;
    }
  }

  .seeMoreTwo {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #304FFE;
    text-align: right;
    transition: letter-spacing 0.5s;

    &:hover {
      letter-spacing: 2px;
      cursor: pointer;
    }
  }

  .imgArrow {
    margin-left: 1rem;
  }

  .gridxs {
    display: none;
  }

  .gridCircle {
    display: flex;
    justify-content: flex-end;
  }

  .animationxs {
    display: none;
  }

  .animation {
    display: none;
  }

  .animationsm {
    width: 100%;
  }
}

@media (min-width: 1015px) and (max-width: 1150px) {
  .main {
    min-height: 110vh;
    background-color: #333333;
    margin-top: -2rem;
  }

  .brmd {
    display: none;
  }

  .card {
    width: 45%;
    margin: auto;
    height: 65px;
    margin-top: -4rem;
    background-color: #333333;
    border-radius: 2px;
  }

  .divBackground {
    width: 100%;
    height: 110vh;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text {
    position: relative;
    width: 80%;
    margin: auto;
  }

  .title {
    padding-top: 8rem;
    display: flex;
    align-items: flex-start;
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 64px;
    color: #FFFFFF;
  }

  .titleGrid {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
  }

  .textGrid {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
  }

  .grid {
    padding-top: 5rem;
  }

  .stepNumBox {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
  }

  .stepIcon {
    background: #FF6600;
    width: 140px;
    height: 140px;
    position: relative;
    border: 3px solid #304FFE;
    border-radius: 50%;
    line-height: 140px;
    font-size: 50px;
    text-align: center;
    color: #ffffff;
    font-weight: 700;
  }

  .seeMore {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #304FFE;
    transition: letter-spacing 0.5s;

    &:hover {
      letter-spacing: 2px;
      cursor: pointer;
    }
  }

  .imgArrow {
    margin-left: 1rem;
  }

  .gridmd {
    display: none;
  }

  .animationxs {
    display: none;
  }

  .animationsm {
    display: none;
  }

  .animation {
    width: 100%;
  }
}

@media (min-width: 1150px) and (max-width: 1345px) {
  .main {
    min-height: 110vh;
    background-color: #333333;
    margin-top: -2rem;
  }

  .brmd {
    display: none;
  }

  .card {
    width: 45%;
    margin: auto;
    height: 65px;
    margin-top: -4rem;
    background-color: #333333;
    border-radius: 2px;
  }

  .divBackground {
    width: 100%;
    height: 110vh;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text {
    position: relative;
    width: 80%;
    margin: auto;
  }

  .title {
    padding-top: 8rem;
    display: flex;
    align-items: flex-start;
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 64px;
    color: #FFFFFF;
  }

  .titleGrid {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
  }

  .textGrid {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
  }

  .grid {
    padding-top: 5rem;
  }

  .stepNumBox {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
  }

  .stepIcon {
    background: #FF6600;
    width: 140px;
    height: 140px;
    position: relative;
    border: 3px solid #304FFE;
    border-radius: 50%;
    line-height: 140px;
    font-size: 50px;
    text-align: center;
    color: #ffffff;
    font-weight: 700;
  }

  .seeMore {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #304FFE;
    transition: letter-spacing 0.5s;

    &:hover {
      letter-spacing: 2px;
      cursor: pointer;
    }
  }

  .imgArrow {
    margin-left: 1rem;
  }

  .gridmd {
    display: none;
  }

  .animationxs {
    display: none;
  }

  .animationsm {
    display: none;
  }

  .animation {
    width: 100%;
  }
}

@media (min-width: 1345px) {
  .main {
    min-height: 100vh;
    background-color: #333333;
    margin-top: 0;
    padding-top: 0;
  }

  .brmd {
    display: none;
  }

  .card {
    width: 45%;
    margin: auto;
    height: 65px;
    margin-top: -4rem;
    background-color: #333333;
    border-radius: 2px;
  }

  .divBackground {
    width: 100%;
    margin-top: -3rem;
    position: absolute;
  }

  .text {
    position: relative;
    width: 80%;
    margin: auto;
  }

  .title {
    padding-top: 8rem;
    display: flex;
    align-items: flex-start;
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 64px;
    color: #FFFFFF;
  }

  .titleGrid {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
  }

  .textGrid {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
  }

  .grid {
    padding-top: 5rem;
  }

  .stepNumBox {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
  }

  .stepIcon {
    background: #FF6600;
    width: 140px;
    height: 140px;
    position: relative;
    border: 3px solid #304FFE;
    border-radius: 50%;
    line-height: 140px;
    font-size: 50px;
    text-align: center;
    color: #ffffff;
    font-weight: 700;
  }

  .seeMore {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #304FFE;
    transition: letter-spacing 0.5s;

    &:hover {
      letter-spacing: 2px;
      cursor: pointer;
    }
  }

  .imgArrow {
    margin-left: 1rem;
  }

  .gridmd {
    display: none;
  }

  .animationxs {
    display: none;
  }

  .animationsm {
    display: none;
  }

  .animation {
    width: 100%;
  }
}
</style>

<template>
  <div class="container">
    <div class="lottiexs">
      <lottie1
        :options="defaultOptions1"
        :heigth="1500"
        :width="900"
      />
    </div>
    <div class="lottiesm">
      <lottie1
        :options="defaultOptions1"
        :heigth="1500"
        :width="1500"
      />
    </div>
    <lottie1
      class="lottie"
      :options="defaultOptions"
    />
  </div>
</template>

<script>
import Lottie1 from 'vue-lottie';
import animationData from '../../assets/animation/Toaste.json';
import animationData1 from '../../assets/animation/responsive/Toastrespon.json';
export default {
  name: 'Toast',
  components: { Lottie1 },
  data() {
    return {
      defaultOptions: {
        animationData: animationData,
        loop: false,
        autoplay: true
      },
      defaultOptions1: {
        animationData: animationData1,
        loop: false,
        autoplay: true
      }
    };
  }
};
</script>
<style lang="stylus" scoped>
@media (max-width: 1200px) {
  .container {
    position: fixed;
    z-index: 13;
    width: 100vw;
    height: 90vh;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .lottiexs {
    display: block;
    margin-left: 3rem;
  }

  .lottie {
    display: none;
  }

  .lottiesm {
    display: none;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .container {
    position: fixed;
    z-index: 13;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .lottiesm {
    display: block;
    margin-left: 3rem;
  }

  .lottie {
    display: none;
  }

  .lottiexs {
    display: none;
  }
}

@media (min-width: 1400px) {
  .container {
    position: fixed;
    z-index: 13;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .lottie {
    display: block;
  }

  .lottiexs {
    display: none;
  }

  .lottiesm {
    display: none;
  }
}
</style>

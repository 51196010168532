<template>
  <div>
    <div class="main">
      <q-toolbar
        class="toolbar"
        :class="{
          toolbarActive: scrollServices || scrollTechnologies,
          toolbarActiveOrange: scrollTeam && !blackTeam
        }"
      >
        <q-btn flat @click="btnHome">
          <img
            v-if="
              scrollMain ||
              scrollGet ||
              scrollServices ||
              scrollTechnologies ||
              blackTeam
            "
            alt="logo"
            src="../assets/logo.svg"
            class="logo"
          />
          <img
            v-if="scrollTeam && !blackTeam"
            alt="logo"
            src="../assets/logoWhite.svg"
            class="logo"
          />
        </q-btn>
        <q-toolbar-title />
        <div class="divButtons">
          <router-link v-scroll-to="'#home'" class="href" to="#">
            <q-btn
              :class="[
                {
                  btnBarActive: scrollMain,
                  btnBar: !scrollTeam || blackTeam,
                  btnBarOrange: scrollTeam && !blackTeam
                }
              ]"
              flat
              no-caps
            >
              Home
            </q-btn>
          </router-link>
          <router-link v-scroll-to="'#services'" class="href" to="#service">
            <q-btn
              :class="[
                {
                  btnBarActiveServices: scrollServices,
                  btnBar: !scrollTeam || blackTeam,
                  btnBarOrange: scrollTeam && !blackTeam
                }
              ]"
              flat
              no-caps
            >
              <div>Services</div>
            </q-btn>
          </router-link>
          <router-link
            v-scroll-to="'#technologies'"
            class="href"
            to="#technologies"
          >
            <q-btn
              :class="[
                {
                  btnBarActiveTechnologies: scrollTechnologies,
                  btnBar: !scrollTeam || blackTeam,
                  btnBarOrange: scrollTeam && !blackTeam
                }
              ]"
              flat
              no-caps
            >
              <div>Technologies</div>
            </q-btn>
          </router-link>
          <router-link v-scroll-to="'#team'" class="href" to="#team">
            <q-btn
              flat
              :class="[
                {
                  btnBarActiveTeam: scrollTeam,
                  btnBar: !scrollTeam || blackTeam,
                  btnBarOrange: scrollTeam && !blackTeam
                }
              ]"
              no-caps
            >
              Team
            </q-btn>
          </router-link>
          <router-link v-scroll-to="'#started'" class="href" to="#started">
            <q-btn
              flat
              :class="[
                {
                  btnBarActive: scrollGet,
                  btnBar: !scrollTeam || blackTeam,
                  btnBarOrange: scrollTeam && !blackTeam
                }
              ]"
              no-caps
            >
              Get started
            </q-btn>
          </router-link>
        </div>
        <q-btn
          v-if="!scrollTeam || blackTeam"
          class="buttonLet"
          no-caps
          @click="alert = true"
        >
          Contact Us
        </q-btn>
        <q-btn
          v-if="scrollTeam && !blackTeam"
          outline
          class="buttonLet"
          no-caps
          @click="alert = true"
        >
          Contact Us
        </q-btn>
        <!-- <q-btn flat @click="drawer = !drawer" class="brmd"><img src="../assets/icons/icon-burger.svg" alt="burger"></q-btn> -->
      </q-toolbar>
    </div>

    <div class="mainxs">
      <q-toolbar
        class="toolbar"
        :class="{
          toolbarActive: scrollServices || scrollTechnologies,
          toolbarActiveOrange: scrollTeam && !blackTeam
        }"
      >
        <q-btn dense flat @click="btnHome">
          <img
            v-if="scrollTeam && !blackTeam"
            alt="logo"
            src="../assets/logoWhite.svg"
            class="logo"
          />
          <img
            v-if="
              scrollServices ||
              scrollMain ||
              scrollGet ||
              scrollTechnologies ||
              blackTeam
            "
            alt="logo"
            src="../assets/logo.svg"
            class="logo"
          />
        </q-btn>
        <q-space />
        <q-btn
          v-if="(!scrollTeam && !drawer) || (blackTeam && !drawer)"
          class="buttonLet"
          @click="alert = true"
        >
          Contact Us
        </q-btn>

        <q-btn
          v-if="scrollTeam && !drawer && !blackTeam"
          outline
          class="buttonLet"
          @click="alert = true"
        >
          Contact Us
        </q-btn>

        <!-- <q-btn flat @click="drawer = !drawer" class="brmd"><img src="../assets/icons/icon-burger.svg" alt="burger"></q-btn> -->
        <q-btn style="position: relative; z-index: 12" dense>
          <div :class="['container', { change: drawer }]" @click="myFunction">
            <div
              class="bar1"
              :class="{ bar1active: scrollServices || scrollTechnologies }"
            />
            <div
              class="bar2"
              :class="{ bar2active: scrollServices || scrollTechnologies }"
            />
            <div
              class="bar3"
              :class="{ bar3active: scrollServices || scrollTechnologies }"
            />
          </div>
        </q-btn>
      </q-toolbar>
    </div>

    <div class="mainsm">
      <q-toolbar
        class="toolbar"
        :class="{
          toolbarActive: scrollServices || scrollTechnologies,
          toolbarActiveOrange: scrollTeam && !blackTeam
        }"
      >
        <q-btn flat @click="btnHome">
          <img
            v-if="scrollTeam && !blackTeam"
            alt="logo"
            src="../assets/logoWhite.svg"
            class="logo"
          />
          <img
            v-if="
              scrollServices ||
              scrollMain ||
              scrollGet ||
              scrollTechnologies ||
              blackTeam
            "
            alt="logo"
            src="../assets/logo.svg"
            class="logo"
          />
        </q-btn>
        <q-toolbar-title />
        <q-btn
          v-if="!scrollTeam || blackTeam"
          class="buttonLet"
          no-caps
          @click="alert = true"
        >
          Contact Us
        </q-btn>
        <q-btn
          v-if="scrollTeam && !blackTeam"
          outline
          class="buttonLet"
          no-caps
          @click="alert = true"
        >
          Contact Us
        </q-btn>
        <!-- <q-btn flat @click="drawer = !drawer" class="brmd"><img src="../assets/icons/icon-burger.svg" alt="burger"></q-btn> -->
        <q-btn style="position: relative; z-index: 15">
          <div :class="['container', { change: drawer }]" @click="myFunction">
            <div
              class="bar1"
              :class="{ bar1active: scrollServices || scrollTechnologies }"
            />
            <div
              class="bar2"
              :class="{ bar2active: scrollServices || scrollTechnologies }"
            />
            <div
              class="bar3"
              :class="{ bar3active: scrollServices || scrollTechnologies }"
            />
          </div>
        </q-btn>
      </q-toolbar>
    </div>

    <!-- Modal Contact -->

    <q-dialog v-model="alert" full-width full-height>
      <Splash v-if="loading" />

      <q-card>
        <div class="divBackground">
          <Lottie1 class="animation" :options="defaultOptions" />
          <Lottie1 class="animationxs" :options="defaultOptions1" />
        </div>

        <div class="texclose">
          <q-card-actions class="" align="right">
            <a class="href" href="#home"
              ><q-btn flat icon="close" @click="closeModal"
            /></a>
          </q-card-actions>
        </div>

        <div class="text dialog-container">
          <!-- <q-card-actions class="btnxs" align="right">
            <a class="href" href="#home"
              ><q-btn
                flat
                style="margin-top: 3.2rem; font-size: 18px"
                icon="close"
                @click="closeModal"
            /></a>
          </q-card-actions> -->

          <div class="row q-col-gutter-xs justify-center">
            <div class="col-xs-10 col-md-6">
              <div class="titleGrid">
                <div class="rectangle">
                  <img src="../assets/icons/RectangleOrange.svg" alt="orange" />
                </div>
                Get in touch
              </div>

              <p class="subTitleGrid">with a Business Analyst</p>
              <p class="textGrid">
                Our experts will help you build <br class="btnlg" />
                solutions with the most advanced <br class="btnlg" />
                and reliable technologies at the best <br class="btnlg" />
                cost.
              </p>

              <div class="brxs divicons">
                <img
                  v-for="item in social"
                  :key="item.name"
                  class="social"
                  :src="item.src"
                  :alt="item.alt"
                  @click="goToLink(item.link)"
                />
              </div>

              <div class="brmd divicons">
                <img
                  v-for="item in social"
                  :key="item.name"
                  class="social"
                  :src="item.src"
                  :alt="item.alt"
                  @click="goToLink(item.link)"
                />
              </div>

              <div class="textContact">
                <p>+ 1 425-524-5242</p>
                <p>david@300dev.com</p>
                <p class="policies">
                  <u> Cookies Policies </u>
                </p>
              </div>
            </div>

            <div
              class="col-xs-10 col-md-5 q-pb-xl"
              :class="{ 'q-pt-xl': $q.screen.lt.md }"
            >
              <q-input
                ref="name"
                v-model="contact.nameFull"
                outlined
                class="textField"
                label="Name"
                :rules="[
                  (val) =>
                    (val !== null && val !== '') || 'Please type your Name'
                ]"
              />
              <q-input
                ref="email"
                v-model="contact.email"
                outlined
                class="textField"
                label="Email"
                :rules="[
                  (val) => !!val || 'Please type your Email',
                  isValidEmail
                ]"
              />
              <q-input
                ref="phone"
                v-model="contact.number"
                outlined
                class="textField"
                label="Number Phone"
                mask="(###) ### - ####"
                unmasked-value
                :rules="[
                  (val) =>
                    (val !== null && val !== '') || 'Please type your Phone'
                ]"
              />
              <q-input
                ref="message"
                v-model="contact.text"
                outlined
                class="textField"
                type="textarea"
                label="Write something..."
                :rules="[
                  (val) =>
                    (val !== null && val !== '') || 'Please type your Message'
                ]"
              />

              <div class="mdBtn">
                <q-btn
                  class="buttonContact full-width"
                  padding="25px"
                  no-caps
                  @click="send"
                >
                  Contact Us
                  <img
                    style="margin-left: 1rem"
                    src="../assets/icons/ArrowWhite.svg"
                    alt="ArrowWhite"
                  />
                </q-btn>
              </div>
            </div>
          </div>
        </div>
      </q-card>
    </q-dialog>

    <!-- Drawer -->
    <Drawer
      :scroll-active="{
        main: scrollMain,
        services: scrollServices,
        technologies: scrollTechnologies,
        team: scrollTeam,
        started: scrollGet
      }"
      :open-contact="openContact"
      :close-drawer="closeDrawer"
      :drawer="drawer"
    />

    <Toast v-if="toast" />
  </div>
</template>
<script>
import './contac.css';
import { db } from '../firebase/firebase';
import Lottie1 from 'vue-lottie';
import animationData from '../assets/animation/GETINTOUCH.json';
import animationData2 from '../assets/animation/responsive/GetinTouch.json';
import Splash from '../components/landingnew/Splash.vue';
import Toast from '../components/landingnew/Toast.vue';
import { scrollTop } from '../utils/scroll.js';
import { isValidEmail } from '../utils/validations.js';
import { analytics } from '../firebase/firebase';
import Drawer from '../components/landingnew/Drawer.vue';

export default {
  name: 'NavBar',
  components: { Lottie1, Splash, Toast, Drawer },
  props: {
    scrollTeam: Boolean,
    getScrollTeam: { type: Function, default: undefined },
    scrollTechnologies: Boolean,
    getScrollTechnologies: { type: Function, default: undefined },
    scrollServices: Boolean,
    getScrollServices: { type: Function, default: undefined },
    active: Boolean,
    getScrollActive: { type: Function, default: undefined },
    activeTeam: Boolean,
    getScrollActiveTeam: { type: Function, default: undefined },
    scrollGet: Boolean,
    getScrollGet: { type: Function, default: undefined }
  },
  data() {
    return {
      alert: false,
      drawer: false,
      contact: {
        nameFull: null,
        email: null,
        number: null,
        text: null
      },
      scrollMain: true,
      timeout: false,
      defaultOptions: {
        animationData: animationData,
        loop: false,
        autoplay: true
      },
      defaultOptions1: {
        animationData: animationData2,
        loop: false,
        autoplay: true
      },
      toggled: false,
      loading: false,
      validateRecaptcha: false,
      validateBtn: true,
      toast: false,
      screen: 'N',
      blackTeam: false,
      window: {
        width: 0,
        height: 0
      },
      social: [
        {
          name: 'Twitter',
          src: require('../assets/icons/icon-twitter.svg'),
          alt: 'tw',
          link: 'https://twitter.com/300dev_?s=09'
        },
        {
          name: 'Facebook',
          src: require('../assets/icons/icon-fb.svg'),
          alt: 'fb',
          link: 'https://www.facebook.com/300dev'
        },
        {
          name: 'Linkedin',
          src: require('../assets/icons/icon-in.svg'),
          alt: 'in',
          link: 'https://www.linkedin.com/company/300dev/about/'
        },
        {
          name: 'Instagram',
          src: require('../assets/icons/icon-instagram.svg'),
          alt: 'ins',
          link: 'https://instagram.com/300dev_?igshid=vn93jjx2qb1b'
        },
        {
          name: 'Tiktok',
          src: require('../assets/icons/icon-tiktok.svg'),
          alt: 'tktk',
          link: 'https://vm.tiktok.com/ZMJGXcAma/'
        }
      ]
    };
  },
  mounted() {
    this.handleScroll;
    window.addEventListener('scroll', this.scrollListener);
    this.handleResize();
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  },
  beforeDestroy: function () {
    window.removeEventListener('scroll', this.scrollListener);
  },
  methods: {
    scrollTop: scrollTop,
    isValidEmail: isValidEmail,
    goToLink(link) {
      window.open(link, '_blank');
    },
    scrollListener: function () {
      this.visible = window.scrollY > 150;
    },
    closeModal() {
      this.alert = !this.alert;
      this.reset();
    },
    btnHome() {
      if (this.$route.name != 'Home') {
        this.$router.push('/');
      }
    },
    openContact() {
      this.alert = !this.alert;
      this.drawer = !this.drawer;
    },
    closeDrawer() {
      this.drawer = !this.drawer;
    },
    close() {
      console.log('Bye');
    },
    send() {
      this.$refs.name.validate();
      this.$refs.email.validate();
      this.$refs.phone.validate();
      this.$refs.message.validate();

      if (
        this.$refs.name.hasError ||
        this.$refs.email.hasError ||
        this.$refs.phone.hasError ||
        this.$refs.message.hasError
      ) {
        this.formHasError = true;
      } else {
        this.loading = true;
        this.$recaptcha('contact').then(() => {
          db.collection('contact')
            .add(this.contact)
            .then(() => {
              analytics.logEvent('form_sent');
              this.byeLoader();
            })
            .catch((err) => {
              this.loading = false;
              this.$q.notify({
                type: 'negative',
                message: `${err}`
              });
            });
        });
      }
    },
    reset() {
      this.contact.nameFull = null;
      this.contact.email = null;
      this.contact.number = null;
      this.contact.text = null;
      (this.validateRecaptcha = false), (this.validateBtn = true);
    },
    handleScroll() {
      const documentScrollHeight = document.documentElement.scrollHeight;
      const windowInnerHeight = window.innerHeight;
      this.scrollHeight = documentScrollHeight - windowInnerHeight;
      this.scrollPosition = Math.round(window.scrollY + 1);

      console.log('width: ', this.window.width);
      console.log('height: ', this.window.height);

      if (this.scrollPosition > 100) this.getScrollActive(true);

      if (this.scrollPosition > 2500) this.getScrollActiveTeam(true);

      if (this.scrollPosition > 6400) this.getScrollActiveTeam(true);

      if (this.scrollPosition > 4600) this.getScrollActiveTeam(true);

      if (this.window.width <= 600) {
        // Extra Small Resolution
        if (this.scrollPosition < 640) {
          this.scrollMain = true;
          this.toggleNavbarStatus(false, false, false, false);
        }

        if (this.scrollPosition >= 640 && this.scrollPosition <= 2300) {
          this.scrollMain = false;
          this.toggleNavbarStatus(true, false, false, false);
        }

        if (this.scrollPosition > 2300 && this.scrollPosition <= 4600) {
          this.toggleNavbarStatus(false, true, false, false);
        }

        if (this.scrollPosition > 4600 && this.scrollPosition <= 6100) {
          this.toggleNavbarStatus(false, false, true, false);
          if (this.scrollPosition > 5250 && this.scrollPosition <= 6100) {
            this.blackTeam = true;
          } else {
            this.blackTeam = false;
          }
        }

        if (this.scrollPosition > 6100) {
          this.toggleNavbarStatus(false, false, false, true);
          this.blackTeam = false;
        }
      } else if (this.window.width > 600 && this.window.width < 1015) {
        // Small Resolution
        if (this.scrollPosition < 640) {
          this.scrollMain = true;
          this.toggleNavbarStatus(false, false, false, false);
        }

        if (this.scrollPosition >= 640 && this.scrollPosition <= 1700) {
          this.scrollMain = false;
          this.toggleNavbarStatus(true, false, false, false);
        }

        if (this.scrollPosition > 1700 && this.scrollPosition <= 4850) {
          this.toggleNavbarStatus(false, true, false, false);
        }

        if (this.scrollPosition > 4850 && this.scrollPosition <= 6950) {
          this.toggleNavbarStatus(false, false, true, false);
          if (this.scrollPosition > 5500 && this.scrollPosition <= 6950) {
            this.blackTeam = true;
          } else {
            this.blackTeam = false;
          }
        }

        if (this.scrollPosition > 6950) {
          this.toggleNavbarStatus(false, false, false, true);
          this.blackTeam = false;
        }
      } else {
        // Normal Resolution
        if (this.scrollPosition < 680) {
          this.scrollMain = true;
          this.toggleNavbarStatus(false, false, false, false);
        }

        if (this.scrollPosition >= 680 && this.scrollPosition <= 1700) {
          this.scrollMain = false;
          this.toggleNavbarStatus(true, false, false, false);
        }

        if (this.scrollPosition > 1700 && this.scrollPosition <= 3450) {
          this.toggleNavbarStatus(false, true, false, false);
        }

        if (this.scrollPosition > 3450 && this.scrollPosition <= 5250) {
          this.toggleNavbarStatus(false, false, true, false);
          if (this.scrollPosition > 4450 && this.scrollPosition <= 5250) {
            this.blackTeam = true;
          } else {
            this.blackTeam = false;
          }
        }

        if (this.scrollPosition > 5250) {
          this.toggleNavbarStatus(false, false, false, true);
          this.blackTeam = false;
        }
      }

      if (this.scrollPosition >= this.scrollHeight) {
        this.alert = true;
        this.scrollTop();
      }
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      this.handleScroll();
    },
    toggleNavbarStatus(service, technologies, team, gets) {
      this.getScrollServices(service);
      this.getScrollTechnologies(technologies);
      this.getScrollTeam(team);
      this.getScrollGet(gets);
    },
    myFunction() {
      this.toggled = !this.toggled;
      this.drawer = !this.drawer;
    },
    byeLoader() {
      var v = this;
      setTimeout(() => {
        v.loading = false;
        v.alert = false;
        v.scrollTop();
        v.toaste();
        v.reset();
      }, 2000);
    },
    toaste() {
      this.toast = true;
      var v = this;
      setTimeout(() => {
        v.toast = false;
      }, 3000);
    },
    validateRE(success) {
      this.validateRecaptcha = success;
      if (this.validateRecaptcha != false) {
        this.validateBtn = false;
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
.href {
  color: black;
  text-decoration: none;
}

.rectangle {
  padding-right: 10px;
}

.titleGrid {
  display: flex;
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 64px;
  color: #333333;
}

.dialog-container {
  padding-top: 3rem;
}


.textContact {
  padding-top: 4rem;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 28px;
  color: #333333;
}

.policies {
  color: blue;
}

@media (max-width: 600px) and (max-height: 400px) {
  .btnlg {
    display: none;
  }

  .brxs {
    display: none !important;
  }

  .texclose {
    position: relative;
  }

  .text {
    position: relative;
    width: 95%;
    margin: auto;
  }

  .divBackground {
    margin-top: 7rem;
    min-width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
  }

  .textBackground {
    font-family: Raleway;
    font-style: normal;
    font-weight: 1000;
    font-size: 116px;
    line-height: 148px;
    color: #FAFAFA;
  }

  .pruebaaa {
    text-align: center;
  }

  .main {
    position: relative;
    max-width: 100%;
  }

  .toolbar {
    max-width: 100%;
    margin: auto;
    background-color: #333333;
    position: fixed;
    z-index: 10;
    padding: 1rem;
  }

  .divButtons {
    display: none;
  }

  .buttonLet {
    color: white;
    font-size: 0.8em;
    background-color: #FF6600;
    padding: 2px 5px;
    font-family: Montserrat;
  }

  .icon {
    margin-left: 0.5rem;
  }

  .hola {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .grid {
    padding-top: 1rem;
  }

  .subTitleGrid {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: #333333;
  }

  .textGrid {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 28px;
    color: #111111;
  }

  .divicons {
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
    margin-top: 2rem;
  }

  .social {
    margin-right: 1rem;
  }

  .textField {
    padding: 1rem;
  }

  .buttonContact {
    width: 90%;
    margin: 1rem;
    color: white;
    background-color: #FF6600;
    padding: 5px 20px;
    font-weight: bold;
    font-family: Montserrat;

    &:hover {
      letter-spacing: 2px;
    }
  }

  .recaptchaS {
    margin: 1rem;
    transform: scale(0.9);
    transform-Origin: 0 0;
  }

  .logo {
    width: 60px;
  }

  .animation {
    display: none;
  }

  .animationxs {
    width: 85% !important;
  }
}

@media (max-width: 600px) and (min-height: 400px) and (max-height: 500px) {
  .btnlg {
    display: none;
  }

  .brxs {
    display: none !important;
  }

  .texclose {
    position: relative;
  }

  .text {
    position: relative;
    width: 95%;
    margin: auto;
  }

  .divBackground {
    margin-top: 7rem;
    min-width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
  }

  .textBackground {
    font-family: Raleway;
    font-style: normal;
    font-weight: 1000;
    font-size: 116px;
    line-height: 148px;
    color: #FAFAFA;
  }

  .pruebaaa {
    text-align: center;
  }

  .main {
    position: relative;
    max-width: 100%;
  }

  .toolbar {
    max-width: 100%;
    margin: auto;
    background-color: #333333;
    position: fixed;
    z-index: 10;
    padding: 1rem;
  }

  .divButtons {
    display: none;
  }

  .buttonLet {
    color: white;
    font-size: 0.8em;
    background-color: #FF6600;
    padding: 2px 5px;
    font-family: Montserrat;
  }

  .icon {
    margin-left: 0.5rem;
  }

  .hola {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .grid {
    padding-top: 1rem;
  }

  .titleGrid {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 64px;
    color: #333333;
  }

  .subTitleGrid {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: #333333;
  }

  .textGrid {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 28px;
    color: #111111;
  }

  .divicons {
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
    margin-top: 2rem;
  }

  .social {
    margin-right: 1rem;
  }

  .textField {
    padding: 1rem;
  }

  .buttonContact {
    width: 90%;
    margin: 1rem;
    color: white;
    background-color: #FF6600;
    padding: 5px 20px;
    font-weight: bold;
    font-family: Montserrat;

    &:hover {
      letter-spacing: 2px;
    }
  }

  .recaptchaS {
    margin: 1rem;
    transform: scale(0.9);
    transform-Origin: 0 0;
  }

  .logo {
    width: 60px;
  }

  .animation {
    display: none;
  }

  .animationxs {
    width: 85%;
  }
}

@media (max-width: 600px) and (min-height: 500px) {
  .btnlg {
    display: none;
  }

  .brxs {
    display: none !important;
  }

  .texclose {
    position: relative;
  }

  .text {
    position: relative;
    display: flex;
    margin: auto;
  }

  .divBackground {
    margin-top: 7rem;
    min-width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
  }

  .textBackground {
    font-family: Raleway;
    font-style: normal;
    font-weight: 1000;
    font-size: 116px;
    line-height: 148px;
    color: #FAFAFA;
  }

  .pruebaaa {
    text-align: center;
  }

  .mainxs {
    position: relative;
    max-width: 100%;
  }

  .mainsm {
    display: none;
  }

  .main {
    display: none;
  }

  .toolbar {
    max-width: 100%;
    margin: auto;
    background-color: #333333;
    position: fixed;
    z-index: 10;
    padding: 0.3rem 1rem 0.3rem 0.8rem;
  }

  .divButtons {
    display: none;
  }

  .buttonLet {
    color: white;
    font-size: 14px;
    line-height: 17px;
    font-style: normal;
    font-weight: 600;
    background-color: #FF6600;
    padding: 5px 5px;
    font-family: Montserrat;
  }

  .icon {
    margin-left: 0.5rem;
  }

  .hola {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .grid {
    padding-top: 1rem;
  }

  .titleGrid {
    display: flex;
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 55px;
    color: #333333;
  }

  .subTitleGrid {
    padding-top: 2rem;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: #333333;
  }

  .textGrid {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #111111;
  }

  .divicons {
    padding-top: 1.7rem;
    display: flex;
    justify-content: start;

  }

  .social {
    margin-right: 1rem;
  }

  .textField {
    padding-top: 0.8rem;
  }

  .buttonContact {
    margin: 0.8rem;
    color: #FFFFFF;
    background-color: #FF6600;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    &:hover {
      letter-spacing: 2px;
    }
  }

  .recaptchaS {
    margin: 1rem;
    transform: scale(0.9);
    transform-Origin: 0 0;
  }

  .logo {

  }

  .animation {
    display: none;
  }

  .animationxs {
    width: 85% !important;
  }

  .toolbarActive {
    max-width: 100%;
    margin: auto;
    background-color: #FFFFFF;
    position: fixed;
    z-index: 10;
    padding: 0.3rem 1rem 0.3rem 0.8rem;
  }

  .toolbarActiveOrange {
    max-width: 100%;
    margin: auto;
    background-color: #FF6600;
    position: fixed;
    z-index: 10;
    padding: 0.3rem 1rem 0.3rem 0.8rem;
  }


}

@media (min-width: 601px) and (max-width: 1015px) {
  .btnlg {
    display: none;
  }

  .brxs {
    display: none !important;
  }

  .text {
    position: relative;
    width: 95%;
    margin: auto;
  }

  .divBackground {
    margin-top: 7rem;
    min-width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
  }

  .textBackground {
    font-family: Raleway;
    font-style: normal;
    font-weight: 1000;
    font-size: 240px;
    line-height: 230px;
    color: #FAFAFA;
  }

  .pruebaaa {
    text-align: center;
  }

  .main {
    display: none;
  }

  .mainsm {
    display: block;
    position: relative;
    max-width: 100%;
  }

  .mainxs {
    display: none;
  }

  .toolbar {
    max-width: 100%;
    margin: auto;
    background-color: #333333;
    position: fixed;
    z-index: 10;
    padding: 1rem;
  }

  .toolbarActive {
    max-width: 100%;
    margin: auto;
    background-color: #FFFFFF;
    position: fixed;
    z-index: 10;
    padding: 1rem;
  }

  .toolbarActiveOrange {
    max-width: 100%;
    margin: auto;
    background-color: #FF6600;
    position: fixed;
    z-index: 10;
    padding: 1rem;
  }

  .divButtons {
    display: none;
  }

  .buttonLet {
    color: white;
    font-size: 1em;
    background-color: #FF6600;
    padding: 2px 5px;
    font-family: Montserrat;
  }

  .icon {
    margin-left: 0.5rem;
  }

  .hola {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .grid {
    padding-top: 1rem;
  }

  .titleGrid {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 64px;
    color: #333333;
  }

  .subTitleGrid {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: #333333;
  }

  .textGrid {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 28px;
    color: #111111;
  }

  .divicons {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  .social {
    margin-right: 1rem;
  }

  .textField {
    padding: 1rem;
  }

  .buttonContact {
    width: 100%;
    margin: 1rem;
    color: white;
    background-color: #FF6600;
    padding: 5px 20px;
    font-weight: bold;
    font-family: Montserrat;

    &:hover {
      letter-spacing: 2px;
    }
  }

  .recaptchaS {
    margin: 1rem;
  }

  .logo {
    width: 120px;
  }

  .mdBtn {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .animationxs {
    display: none;
  }

  .animation {
    width: 85% !important;
  }

  .href {
    color: black;
    text-decoration: none;
  }
}

@media (min-width: 1015px) {
  .btnxs {
    display: none;
  }

  .text {
    position: relative;
    width: 80%;
    margin: auto;
  }

  .divBackground {
    min-width: 100%;
    position: absolute;
    margin-top: -1rem;
  }

  .textBackground {
    font-family: Raleway;
    font-style: normal;
    font-weight: 1000;
    font-size: 300px;
    line-height: 300px;
    color: #FAFAFA;
  }

  .pruebaaa {
    text-align: center;
  }

  .main {
    display: block;
    position: relative;
    max-width: 100%;
  }

  .mainsm {
    display: none;
  }

  .mainxs {
    display: none;
  }

  .toolbar {
    max-width: 100%;
    margin: auto;
    background-color: #333333;
    position: fixed;
    z-index: 10;
    padding: 1rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .divButtons {
    width: 55%;
  }

  .buttonLet {
    color: white;
    font-family: Montserrat;
    background-color: #FF6600;
    padding: 5px 20px;
  }

  .icon {
    margin-left: 0.5rem;
  }

  .hola {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .grid {
    margin-top: 2rem;
  }

  .titleGrid {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 64px;
    color: #333333;
  }

  .subTitleGrid {
    margin-left: 3rem;
    padding-top: 1rem;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: #333333;
  }

  .textGrid {
    margin-left: 3rem;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #111111;
  }

  .divicons {
    margin-left: 3rem;
    margin-top: 3rem;
  }

  .social {
    margin-right: 1rem;
  }

  .buttonContact {
    width: 100%;
    margin-top: 1rem;
    color: white;
    background-color: #FF6600;
    font-weight: bold;
    padding: 12px 20px;
    font-family: Montserrat;

    &:hover {
      letter-spacing: 2px;
    }
  }

  .brmd {
    display: none;
  }

  .btnBar {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #979797;
  }

  .btnBarOrange {
    font-family: Montserrat;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
  }

  .btnBarActive {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
  }

  .btnBarActiveServices {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #111111;
  }

  .btnBarActiveTechnologies {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #111111;
  }

  .btnBarActiveTeam {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
  }

  .toolbarActive {
    max-width: 100%;
    margin: auto;
    background-color: #FFFFFF;
    position: fixed;
    z-index: 10;
    padding: 1rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .toolbarActiveOrange {
    max-width: 100%;
    margin: auto;
    background-color: #FF6600;
    position: fixed;
    z-index: 10;
    padding: 1rem;
    padding-left: 4rem;
    padding-right: 4rem;
    color: #FFFFFF;
  }

  .animationxs {
    display: none;
  }

  .animation {
    width: 85% !important;
  }

  .textField {
    padding-top: 0.5rem;
  }

  .buttonContact {
    width: 90%;
    margin: 1rem;
    color: white;
    background-color: #FF6600;
    padding: 5px 20px;
    font-weight: bold;
    font-family: Montserrat;

    &:hover {
      letter-spacing: 2px;
    }
  }

  .texclose {
    position: relative;
    margin: 1rem;
  }

  .textContact {
    padding-top: 6rem;
    padding-left: 3rem;
  }

}
</style>

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/analytics';
import { config } from '../config/config';

const fbapp = firebase.initializeApp(config);
const db = firebase.firestore();
const analytics = firebase.analytics();

export { fbapp, db, analytics };

export const scrollFunction = (play, stop) => {
  let isScrolling;
  window.addEventListener(
    'scroll',
    () => {
      if (play) {
        play();
      }
      window.clearTimeout(isScrolling);
      isScrolling = setTimeout(() => {
        if (stop) {
          stop();
        }
      }, 250);
    },
    false
  );
};

export function scrollTop() {
  window.scrollTo(0, 0);
}

export default {
  scrollFunction,
  scrollTop
};

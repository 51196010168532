<template>
  <div class="main">
    <div class="divBackground">
      <Lottie1
        class="animationxs"
        :options="defaultOptions3"
        @animCreated="handleAnimation3"
      />
      <Lottie1
        class="animation"
        :options="defaultOptions"
        @animCreated="handleAnimation1"
      />
    </div>
    <div class="text">
      <div class="title">
        <div class="rectangle">
          <img src="../../assets/icons/RectangleOrange.svg" alt="orange" />
        </div>
        How can We help your business?
      </div>
      <p class="subtitle">
        Our experts will help your business to utilize the most
        <br class="brxs" />
        advanced and reliable technologies at the most <br class="brxs" />
        competitive cost.
      </p>
      <div class="grid row q-col-gutter-x-xl q-col-gutter-y-sm justify-center">
        <div
          v-for="n in 5"
          :key="`xl-${n}`"
          class="col-xs-11 col-sm-6 col-md-4"
        >
          <p class="titleGrid">
            {{ arrayData[n - 1].title }}
          </p>
          <p class="textGrid">
            {{ arrayData[n - 1].description }}
          </p>
          <div>
            <p style="visibility: hidden" class="seeMore">
              See more
              <img
                class="imgArrow"
                src="../../assets/icons/ArrowOrange.svg"
                alt="orange"
              />
            </p>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4">
          <div
            class="divEgg"
            @mouseenter="playAnimation"
            @mouseleave="stopAnimation"
          >
            <Lottie1
              style="width: 100%"
              :options="defaultOptions1"
              :height="350"
              :width="400"
              @animCreated="handleAnimation"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import lottieData from '../../assets/animation/GotaBlanca2.json';
import lottieData1 from '../../assets/animation/responsive/GotaBlanca2.json';
import Lottie1 from 'vue-lottie';
import animationData from '../../assets/animation/GotaActiva3.json';
import { scrollFunction } from '../../utils/scroll';
export default {
  name: 'Services',
  components: {
    Lottie1
  },
  props: { scrollServices: Boolean, active: Boolean },
  data() {
    return {
      lottieData,
      lottieInstance: '',
      array: ['Research Project', 'Targeting', 'Result'],
      activeIndex: 0,
      defaultOptions: {
        animationData: lottieData,
        loop: false,
        autoplay: false
      },
      defaultOptions1: {
        animationData: animationData,
        loop: true,
        autoplay: false
      },
      defaultOptions3: {
        animationData: lottieData1,
        loop: false,
        autoplay: false
      },
      animationSpeed: 1,
      arrayData: [
        {
          title: 'Web development',
          description:
            'From concept to your final product, we’ll guide you through the process of building a quality web app aligned with your business goals.'
        },
        {
          title: 'Mobile Apps',
          description:
            'If you need to build, mobilize or maintain an existing app, which can be Native or Hybrid, our team will help you reach your business goals.'
        },
        {
          title: 'eCommerce/ Marketplaces',
          description:
            'Build a custom solution specifically for your online business and take it to the next level by creating a high-quality eCommerce store or marketplace solution.'
        },
        {
          title: 'Cloud server management',
          description:
            'The cloud team combines the best services, integrations and practices to enable better way of doing business.'
        },
        {
          title: 'AI API mashup and model development',
          description:
            'We use state-of-the-art techniques, whether Deep Learning, Neural Networks or traditional Machine Learning approaches.'
        }
      ]
    };
  },
  created: function () {
    scrollFunction(this.playAnimation1, this.stopAnimation1);
  },

  destroyed() {
    window.removeEventListener('scroll');
  },
  methods: {
    osito() {
      console.log(this.$route);
    },
    getLottieInstance(lottieInstance) {
      this.lottieInstance = lottieInstance;
    },
    play() {
      this.lottieInstance && this.lottieInstance.play();
    },
    stop() {
      this.lottieInstance && this.lottieInstance.pause();
    },
    handleAnimation: function (anim) {
      this.anim = anim;
    },
    handleAnimation1(anim1) {
      this.anim1 = anim1;
    },
    handleAnimation3(anim3) {
      this.anim3 = anim3;
    },
    playAnimation() {
      this.anim.play();
    },
    stopAnimation() {
      this.anim.pause();
    },
    playAnimation1() {
      this.anim1.play();
      this.anim3.play();
    },
    stopAnimation1() {
      this.anim1.pause();
      this.anim3.pause();
    }
  }
};
</script>
<style lang="stylus" scoped>
.main {
  min-height: 130vh;
}

.divBackground {
  margin-top: -2rem;
  min-width: 100%;
  position: absolute;
}

.text {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.rectangle {
  padding-right: 10px;
}

.title {
  width 80%;
  margin-top: 2rem;
  display: flex;
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 55px;
  color: #333333;
}

.subtitle {
  margin-top: 3.8rem;
  margin-bottom: 3rem;
  width: 80%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.grid {
  margin-top: 1rem;
  width: 100%;
}

.titleGrid {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
}

.textGrid {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
}

.divSeeMore {
  display: flex;
  align-items: center;
}

.seeMore {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  color: #FF6600;
  transition: letter-spacing 0.5s;

  &:hover {
    letter-spacing: 2px;
    cursor: pointer;
  }
}

.imgArrow {
  margin-left: 1rem;
}

.containerOngrid {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titleIcon {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
}

.iconImg {
  display: none;
}

.icons {
  margin: 2rem;
  width: 80px;
}

.brxs {
  display: none;
}

.imgIcon {
  opacity: 1;
  transition: opacity 0.5s;
}

.textOnIcon {
  font-size: 20px;
  line-height: 30px;
  font-family: Montserrat;
  color: #FFFFFF;
  position: absolute;
  opacity: 0;
  transition: opacity 0.7s;
}

.itemTecno {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 280px;
  background-color: #ECECEC;
  transition: background-color 0.5s;

  &:hover {
    background-color: #111111;

    .textOnIcon {
      opacity: 1;
    }

    .imgIcon {
      opacity: 0;
    }
  }
}

.divEgg {
  margin-top: -6rem;
  padding-top: 4rem;

  &:hover {
    cursor: pointer;
  }
}

.backgroundSrill {
  display: none;
  justify-content: center;
  margin-top: 90rem;
}

.titleIcons {
  color: #FFCA28;
  font-weight: bold;
}

.animationxs {
  width: 100%;
}

.animation {
  display: none;
}

@media (min-width: 601px) {
  .divBackground {
    position: absolute;
  }

  .text {
    padding-top: 6rem;
  }

  .title {
    justify-content: center;
    font-size: 50px;
    line-height: 64px;
    text-align: center;
    color: #333333;
  }

  .grid {
    margin-top: 3rem;
    width: 80%;
    margin-bottom: 5rem;
  }

  .textGrid {
    font-size: 18px;
  }

  .seeMoreEgg {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .containerOngrid {
    padding-top: 7rem;
  }

  .iconImg {
    margin-top: 2rem;
    display: flex;
    align-items: center;
  }

  .divEgg {
    margin-top: -5.5rem;
  }

  .backgroundSrill {
    display: flex;
    justify-content: center;
    margin-top: 14rem;
  }

  .animation {
    display: block;
    width: 100%;
  }

  .animationxs {
    display: none;
  }

  .brxs {
    display: block;
  }

  .subtitle {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 3.8rem;
    margin-bottom: 3rem;
    width: 80%;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  }

  @media (min-width: 1035px) {
    .main {
      min-height: 110vh;
    }
  }
}
</style>

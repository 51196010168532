var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-drawer',{staticClass:"q-drawerMd",attrs:{"breakpoint":600,"width":320,"overlay":"","side":"right"},model:{value:(_vm.activeDrawer),callback:function ($$v) {_vm.activeDrawer=$$v},expression:"activeDrawer"}},[_c('q-card',{staticClass:"qcardDrawer",attrs:{"square":"","flat":""}},[_c('div',{staticClass:"divBackgroundDrawer"},[_c('Lottie1',{staticClass:"animation-one",attrs:{"options":_vm.defaultOptions}})],1),_c('div',{staticClass:"textDrawer"},[_c('div',{staticClass:"drawer"},[_c('img',{staticClass:"logo",attrs:{"alt":"logo","src":require("../../assets/logo.svg")}}),_c('q-btn',{staticClass:"closeDrawer",attrs:{"flat":"","icon":"close"},on:{"click":function($event){return _vm.closeDrawer()}}})],1),_c('div',{staticClass:"btnsDrawer"},[_vm._l((_vm.newSections),function(newSection){return _c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
            el: newSection.scrollTo,
            onStart: function (element) {
              _vm.closeDrawer();
            }
          }),expression:"{\n            el: newSection.scrollTo,\n            onStart: function (element) {\n              closeDrawer();\n            }\n          }"}],key:newSection.name,staticClass:"href",attrs:{"to":newSection.to}},[_c('q-btn',{class:[newSection.active ? 'btnDrawerWhite' : 'btnDrawer'],attrs:{"flat":"","no-caps":""}},[_c('img',{class:[
                newSection.active
                  ? 'rectangleDrawerActive'
                  : 'rectangleDrawer'
              ],attrs:{"src":require("../../assets/icons/icon-rectangleorangexs.svg"),"alt":"orangexs"}}),_vm._v(" "+_vm._s(newSection.name)+" ")])],1)}),_c('q-btn',{staticClass:"buttonContactDrawer",attrs:{"no-caps":""},on:{"click":function($event){return _vm.closeContact()}}},[_vm._v(" Contact Us ")])],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
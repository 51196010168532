<template>
  <div>
    <loader v-if="loader" />
    <NavBar
      v-if="!loader"
      :active="active"
      :get-scroll-active="getScrollActive"
      :active-team="activeTeam"
      :get-scroll-active-team="getScrollActiveTeam"
      :scroll-team="scrollTeam"
      :get-scroll-team="getScrollTeam"
      :scroll-get="scrollGet"
      :get-scroll-get="getScrollGet"
      :scroll-services="scrollServices"
      :get-scroll-services="getScrollServices"
      :scroll-technologies="scrollTechnologies"
      :get-scroll-technologies="getScrollTechnologies"
    />
    <Main v-if="!loader" id="home" />
    <Services
      v-if="!loader"
      id="services"
      :scroll-services="scrollServices"
      :active="active"
    />
    <Technologies
      v-if="!loader"
      id="technologies"
      :scroll-technologies="scrollTechnologies"
    />
    <Team
      v-if="!loader"
      id="team"
      :scroll-team="scrollTeam"
      :scroll-services="scrollServices"
      :active-team="activeTeam"
    />
    <GetStarted v-if="!loader" id="started" :scroll-get="scrollGet" />
  </div>
</template>

<script>
import NavBar from '../layout/nav';
import Main from '../components/landingnew/Main';
import Services from '../components/landingnew/Services';
import Team from '../components/landingnew/Team';
import GetStarted from '../components/landingnew/GetStarted';
import Loader from '../components/landingnew/Splash';
import Technologies from '../components/landingnew/Technologies';

export default {
  name: 'Landing',
  components: {
    Main,
    Services,
    Team,
    GetStarted,
    NavBar,
    Loader,
    Technologies
  },

  data() {
    return {
      leftDrawerOpen: false,
      scrollTeam: false,
      scrollServices: false,
      scrollTechnologies: false,
      active: false,
      activeTeam: false,
      scrollGet: false,
      loader: true
    };
  },
  created() {
    this.loadingApp();
  },
  methods: {
    loadingApp() {
      var v = this;
      setTimeout(() => {
        v.loader = false;
      }, 4640);
    },
    getScrollTeam(boolean) {
      this.scrollTeam = boolean;
    },
    getScrollServices(boolean) {
      this.scrollServices = boolean;
    },
    getScrollGet(boolean) {
      this.scrollGet = boolean;
    },
    getScrollActive(boolean) {
      this.active = boolean;
    },
    getScrollActiveTeam(boolean) {
      this.activeTeam = boolean;
    },
    getScrollTechnologies(boolean) {
      this.scrollTechnologies = boolean;
    }
  }
};
</script>
